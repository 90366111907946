<template>
    <div class="totalPadding checkOut">
        <v-container v-if="basket">
            <v-layout row wrap>
                <v-flex sm12 md8>
                    <div class="basket">
                        <h2>Indkøbskurv</h2>
                        <div class="kurvItem" v-for="item in basket.products" :key="item.id">
                            <v-layout row wrap>
                                <v-flex xs12 sm4 md4>
                                    <div class="center">
                                        <img :src="item.product.image" />
                                    </div>
                                </v-flex>
                                <v-flex xs12 sm8 md8>
                                    <button @click="deleteItem(item)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                    <div class="prisen">
                                        <strong>Enhedspris: DKK {{localNumber(item.itemPrice)}}</strong>
                                    </div>
                                    <div class="produktnavn">
                                        {{item.product.description}}
                                    </div>
                                    <div class="varenummer">
                                        Varenummer: {{item.product.no}}
                                    </div>
                                    <span>Antal <input type="number" v-model="item.quantity" @change="changeQuantity(item)" /></span>
                                    <p v-if="!item.product.inStock" class="red--text">Det valgte antal er ikke på lager!</p>
                                </v-flex>
                            </v-layout>
                        </div>
                    </div>
                </v-flex>
                <v-flex sm12 md4>
                    <aside class="kurvOversigt">
                        <h2>Total</h2>
                        <h3 v-if="basket.netsPayment">Rabat <span>DKK {{localNumber(netsDiscount)}}</span></h3>
                        <h3>Fragt <span>DKK {{localNumber(basket.shippingPrice)}}</span></h3>
                        <h3>Moms udgør <span>DKK {{localNumber(basket.vat)}}</span></h3>
                        <h3>Total pris (ekskl. moms) <span>DKK {{localNumber(basket.subtotal)}}</span></h3>
                        <h3 class="totalpris">Total pris (inkl. moms) <span>DKK {{localNumber(basket.totalPrice)}}</span></h3>
                    </aside>

                    <!--<div class="netsPayment" v-if="netsPayment">
                        <v-checkbox label="Betalingsbetingelse 8 dage" v-model="basket.netsPayment" persistent-hint
                                    hint="Betalingsbetingelse 8 dage netto - 1,5% ekstra rabat" light @change="toggleNetsPayment"></v-checkbox>
                    </div>-->
                </v-flex>
            </v-layout>
            <section class="afsendelse">
                <v-layout row wrap>
                    <v-flex xs12 sm6 md4>
                        <h2>Leveringsadresse</h2>

                        <v-radio-group v-model="basket.shippingMethod" row>
                            <v-radio label="Erhverv" value="Erhverv" :disabled="!basket.otherShipping"></v-radio>
                            <v-radio v-if="basket.shippingPrice > 0" label="Privat (+75,-)" value="Privat" :disabled="!basket.otherShipping"></v-radio>
                            <v-radio v-if="basket.shippingPrice <= 0" label="Privat" value="Privat" :disabled="!basket.otherShipping"></v-radio>
                        </v-radio-group>

                        <v-text-field name="Firmanavn"
                                      :disabled="!basket.otherShipping"
                                      v-model="basket.shippingName"
                                      label="Firmanavn"></v-text-field>
                        <v-text-field name="Adresse"
                                      :disabled="!basket.otherShipping"
                                      v-model="basket.shippingAddress"
                                      label="Adresse"></v-text-field>
                        <v-text-field name="Postnummer"
                                      :disabled="!basket.otherShipping"
                                      v-model="basket.shippingZipCode"
                                      label="Postnummer"></v-text-field>
                        <v-text-field name="By"
                                      :disabled="!basket.otherShipping"
                                      v-model="basket.shippingCity"
                                      label="By"></v-text-field>
                        <button class="nyAdresse" v-if="!basket.otherShipping" @click="basket.otherShipping = !basket.otherShipping">
                            Benyt en anden adresse <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </button>
                        <button class="nyAdresse green" v-if="basket.otherShipping" @click="basket.otherShipping = !basket.otherShipping">
                            Benyt firmaadresse <i class="fa fa-check" aria-hidden="true"></i>
                        </button>
                    </v-flex>
                    <v-flex xs12 sm6 md8>
                        <h2>Bemærkninger</h2>

                        <v-dialog persistent
                                  v-model="shippingDateDialog"
                                  lazy
                                  full-width
                                  width="290px">
                            <v-text-field slot="activator"
                                          label="Ønsket afsendelsesdato"
                                          v-model="dateFormatted"
                                          prepend-icon="event"
                                          readonly></v-text-field>
                            <v-date-picker v-model="basket.shippingDate" @input="dateFormatted = formatDate($event)"
                                           scrollable actions locale="da" :allowed-dates="allowedDate">
                                <template slot-scope="{ save, cancel }">
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="primary" @click="cancel">Cancel</v-btn>
                                        <v-btn flat color="primary" @click="save">OK</v-btn>
                                    </v-card-actions>
                                </template>
                            </v-date-picker>
                        </v-dialog>

                        <v-text-field name="Reference"
                                      label="Reference"
                                      hint="Indtast en reference til din bestilling"
                                      persistent-hint
                                      v-model="basket.reference"
                                      type="text"></v-text-field>

                        <v-text-field name="Leveringskommentar"
                                      label="Bemærkning til levering"
                                      multi-line v-model="basket.comment"></v-text-field>
                    </v-flex>
                </v-layout>
            </section>
            <section class="afslut">
                <v-layout wrap row>
                    <v-flex sm12 md6>
                        <!--<span>Jeg har læst og forstået <router-link to="/handels-og-brugerbetingelser">handelsbetingelserne</router-link></span>
            <v-checkbox label="Jeg godkender betingelserne"
            v-model="basket.acceptTerms"
            hide-details></v-checkbox>-->
                    </v-flex>
                    <v-flex sm12 md6>
                        <v-btn class="afgiv orange" @click="createOrder" :loading="loading" :disabled="loading">
                            Gennemfør bestilling <i class="fa fa-check" aria-hidden="true"></i>
                        </v-btn>
                    </v-flex>
                </v-layout>

                <v-alert color="success" icon="check_circle" v-model="success">
                    Tak for din ordre! Den vil blive behandlet hurtigst muligt.
                </v-alert>
            </section>
        </v-container>

        <div class="pageLoad" v-if="!basket">
            <div class="spinner"></div>
        </div>

        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="error.show = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            basket: null,
            netsPayment: false,
            netsDiscount: 0,
            shippingDateDialog: false,
            dateFormatted: null,
            allowedDate: {
                min: new Date().toISOString().substring(0, 10)
            },
            grossists: [
                'Ingen',
                'BD',
                'DJ',
                'LM',
                'AO',
                'P.Hatten',
                'Sanistål',
                'Solar',
                'Anden'
            ],
            loading: false,
            error: {
                show: false,
                message: ''
            },
            success: false
        }),
        components: {},
        computed: {

        },
        watch: {
            'basket.shippingMethod'() {
                this.shippingPrice();
            }
        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            formatDate(date) {
                if (!date) {
                    return null
                }
                return this.$moment(date).format("DD-MM-YYYY");
            },
            toggleNetsPayment(selected) {
                var subTotal = this.basket.subtotal;

                if (selected) {
                    this.basket.subtotal = subTotal * 0.985;
                    this.netsDiscount = subTotal - this.basket.subtotal;
                } else {
                    this.basket.subtotal = (subTotal * 100) / 98.5;
                    this.netsDiscount = 0;
                }

                this.basket.totalPrice = (this.basket.subtotal + this.basket.shippingPrice) * 1.25;
                this.basket.vat = this.basket.totalPrice * 0.2;
            },
            getBasket() {
                return new Promise((resolve, reject) => {
                    this.$http.get("/api/checkout/basket").then(x => {
                        this.basket = x.data;
                        this.dateFormatted = this.formatDate(this.basket.shippingDate);
                        this.allowedDate = { min: x.data.shipableDate.substr(0, 10) };

                        resolve();
                    });
                });
            },
            changeQuantity(item) {
                this.$http.get("/api/checkout/updateitem", {
                    params: {
                        id: item.id,
                        quantity: item.quantity
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    }

                    this.shippingPrice();

                    this.$store.dispatch('getMiniBasket');
                });
            },
            deleteItem(item) {
                this.$http.get("/api/checkout/deleteitem", {
                    params: {
                        id: item.id
                    }
                }).then(x => {
                    this.shippingPrice();

                    this.$store.dispatch('getMiniBasket');
                });
            },
            createOrder() {
                this.loading = true;
                this.$http.post("/api/checkout/createorder", this.basket).then(x => {
                    this.loading = false;

                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        this.success = true;
                    }

                    this.getBasket();
                    this.$store.dispatch('getMiniBasket');
                });
            },
            shippingPrice() {
                this.$http.post("/api/checkout/shippingcost", this.basket).then(x => {
                    this.getBasket();
                });
            }
        },
        created() {
            this.getBasket().then(() => {
                this.shippingPrice();
            });

            this.$http.post("/api/checkout/netspayment").then(x => {
                this.netsPayment = x.data;
            });
        },
        mounted() {

        }
    }
</script>
