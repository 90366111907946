<template>
    <div>
        <div class="topline">
            <h1>Min side <span>/</span> <span>Bestil opstartsservice</span></h1>
        </div>
        <form>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Ønsket opstartsdato</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-menu lazy
                            :close-on-content-click="false"
                            v-model="menu"
                            transition="scale-transition"
                            offset-y
                            full-width
                            :nudge-right="40"
                            max-width="290px"
                            min-width="290px">
                        <v-text-field slot="activator"
                                      label="Ønsket opstartsdato"
                                      v-model="form.date"
                                      prepend-icon="event"
                                      readonly
                                      :error-messages="errors.collect('date')"
                                      v-validate="'required|date_format:DD-MM-YYYY'"
                                      data-vv-as="Installationsdato"
                                      required></v-text-field>
                        <v-date-picker v-model="date" @input="form.date = $moment($event).format('DD-MM-YYYY')" no-title scrollable actions :first-day-of-week="1" locale="da" :allowed-dates="minDays">
                            <template slot-scope="{ save, cancel }">
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn flat color="primary" @click="cancel">Fortryd</v-btn>
                                    <v-btn flat color="primary" @click="save">Vælg</v-btn>
                                </v-card-actions>
                            </template>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
            </v-layout>

            <v-layout row class="mt-4">
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Produkt</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Produkt"
                                  name="product"
                                  v-model="form.product"
                                  :error-messages="errors.collect('product')"
                                  v-validate="'required'"
                                  data-vv-as="Produkt"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Adresse</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Adresse"
                                  name="address"
                                  v-model="form.address"
                                  :error-messages="errors.collect('address')"
                                  v-validate="'required'"
                                  data-vv-as="Adresse"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Postnummer</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Postnummer"
                                  name="zipCode"
                                  v-model="form.zipCode"
                                  :error-messages="errors.collect('zipCode')"
                                  v-validate="'required'"
                                  data-vv-as="Postnummer"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>By</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="By"
                                  name="city"
                                  v-model="form.city"
                                  :error-messages="errors.collect('city')"
                                  v-validate="'required'"
                                  data-vv-as="By"
                                  required></v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Beskrivelse</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Beskrivelse"
                                  name="description" multi-line
                                  v-model="form.description"></v-text-field>
                </v-flex>
            </v-layout>

            <!--<v-text-field name="Leveringskommentar"
                  label="Bemærkning til levering"
                  multi-line v-model="basket.comment"></v-text-field>-->


            <div class="installinfo"></div>

            <template v-if="profile">
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Installatør</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field label="Installatør"
                                      name="installer"
                                      v-model="form.installer"
                                      :error-messages="errors.collect('installer')"
                                      v-validate="'required'"
                                      data-vv-as="Installatør"
                                      required disabled></v-text-field>
                    </v-flex>
                </v-layout>

                <div class="installinfo"></div>
            </template>

            <v-alert color="success" class="my-3" icon="check_circle" v-model="success.show">
                {{success.message}}
            </v-alert>

            <v-alert color="error" class="my-3" icon="warning" v-model="error.show">
                {{error.message}}
            </v-alert>

            <v-btn class="opretKort" @click="submitForm" :loading="loading" :disabled="loading"><i class="fa fa-check" aria-hidden="true"></i> Send forespørgsel</v-btn>

        </form>

    </div>
</template>

<script>
    export default {
        data: () => ({
            date: null,
            menu: false,
            profile: null,
            form: null,
            loading: false,
            success: {
                show: false,
                message: 'Tak for din henvendelse. Vi vender tilbage hurtigst muligt.'
            },
            error: {
                show: false,
                message: "Der opstod en fejl. Prøv igen eller kontakt HS Tarm på info@hstarm.dk."
            },
            minDays: {
                min: null,
                max: null
            }
        }),
        components: {},
        computed: {

        },
        methods: {
            initForm() {
                this.form = {
                    date: null,
                    product: null,
                    address: null,
                    zipCode: null,
                    city: null,
                    description: null,
                    installer: null
                };

                this.errors.clear();
                this.$validator.reset();
            },
            installerInfo() {
                this.$http.get("/api/profile").then(x => {
                    this.profile = x.data;

                    this.form.installer = x.data.firma;

                    this.errors.clear();
                    this.$validator.reset();
                });
            },
            submitForm() {

                this.$validator.validateAll().then(result => {
                    if (result === true) {
                        this.loading = true;

                        this.error.show = false;
                        this.success.show = false;

                        this.$http.post('/api/profile/opstartsservice', this.form).then(x => {
                            if (x.data.error) {
                                this.error.show = true;
                            } else {
                                this.success.show = true;
                                this.initForm();
                                this.installerInfo();

                                this.errors.clear();
                                this.$validator.reset();
                            }

                            this.loading = false;
                        }).catch(err => {
                            this.loading = false;
                        });
                    } else {

                    }
                });

            }
        },
        created() {
            this.minDays.min = this.$moment().add(4, 'days').format("YYYY-MM-DD")

            this.installerInfo();
            this.initForm();
        },
        mounted() {

        }
    }
</script>
