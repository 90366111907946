<template>
    <div>
        <div class="topline">
            <h1>Min side <span>/</span> <span>Registrer garantikort</span></h1>
        </div>
        <form>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Installationsdato</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-menu lazy
                            :close-on-content-click="false"
                            v-model="menu"
                            transition="scale-transition"
                            offset-y
                            full-width
                            :nudge-right="40"
                            max-width="290px"
                            min-width="290px">

                        <v-text-field slot="activator"
                                      name="date"
                                      label="Installationsdato"
                                      v-model="register.date"
                                      prepend-icon="event"
                                      readonly
                                      :error-messages="errors.collect('date')"
                                      v-validate="'required'"
                                      data-vv-as="Installationsdato"
                                      required></v-text-field>

                        <v-date-picker v-model="date" @input="register.date = $moment($event).format('DD-MM-YYYY')" no-title scrollable actions :first-day-of-week="1" locale="da">
                            <template slot-scope="{ save, cancel }">
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn flat color="primary" @click="cancel">Fortryd</v-btn>
                                    <v-btn flat color="primary" @click="save">Vælg</v-btn>
                                </v-card-actions>
                            </template>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
            </v-layout>
            <v-layout row wrap v-for="(item, key) in register.lines" :key="key">
                <v-flex md3>
                    <v-subheader>Serienummer</v-subheader>
                </v-flex>
                <v-flex md6>
                    <v-text-field label="Serienummer"
                                  :name="'serial-' + key"
                                  v-model="item.serial"
                                  :error-messages="errors.collect('serial-' + key)"
                                  v-validate="'required'"
                                  data-vv-as="Serienummer"
                                  required></v-text-field>
                </v-flex>
                <v-flex md3>
                    <v-btn class="colorBlue" :loading="loading" @click="checkSerial(item, 'serial-' + key)">Tjek serienummeret</v-btn>
                </v-flex>

                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Type</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Type"
                                  :name="'type-' + key"
                                  v-model="item.type"
                                  :error-messages="errors.collect('type-' + key)"
                                  v-validate="'required'"
                                  data-vv-as="Type"
                                  required></v-text-field>
                </v-flex>

                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Varenummer</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Varenummer"
                                  :name="'productNumber-' + key"
                                  v-model="item.productNumber"
                                  :error-messages="errors.collect('productNumber-' + key)"
                                  v-validate="'required'"
                                  data-vv-as="Varenummer"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3></v-flex>
                <v-flex sm12 md9>
                    <v-btn class="addLine colorOrange" @click="addProduct">Tilføj et serienummer <i class="fa fa-plus" aria-hidden="true"></i></v-btn>
                    <v-btn class="addLine colorRed" @click="removeProduct()">Fjern et serienummer <i class="fa fa-remove" aria-hidden="true"></i></v-btn>
                </v-flex>
            </v-layout>


            <v-layout row class="mt-4">
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Ejer</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Ejer"
                                  name="owner"
                                  v-model="register.owner"
                                  :error-messages="errors.collect('owner')"
                                  v-validate="'required'"
                                  data-vv-as="Ejer"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Adresse</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Adresse"
                                  name="address"
                                  v-model="register.address"
                                  :error-messages="errors.collect('address')"
                                  v-validate="'required'"
                                  data-vv-as="Adresse"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Postnummer</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Postnummer"
                                  name="zipCode"
                                  v-model="register.zipCode"
                                  :error-messages="errors.collect('zipCode')"
                                  v-validate="'required'"
                                  data-vv-as="Postnummer"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>By</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="By"
                                  name="city"
                                  v-model="register.city"
                                  :error-messages="errors.collect('city')"
                                  v-validate="'required'"
                                  data-vv-as="By"
                                  required></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Telefonnummer</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Telefonnummer"
                                  name="phone"
                                  v-model="register.phone"></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>Mobil</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="Mobil"
                                  name="cellphone"
                                  v-model="register.cellphone"></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-subheader>E-mail</v-subheader>
                </v-flex>
                <v-flex sm12 md9>
                    <v-text-field label="E-mail"
                                  name="email"
                                  v-model="register.email"></v-text-field>
                </v-flex>
            </v-layout>

            <div class="installinfo"></div>

            <template v-if="profile">
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Installatør</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field label="Installatør"
                                      name="installer"
                                      v-model="register.installer"
                                      :error-messages="errors.collect('installer')"
                                      v-validate="'required'"
                                      data-vv-as="Installatør"
                                      required disabled></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Installatør e-mail</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field label="Installatør e-mail"
                                      name="installerEmail"
                                      v-model="register.installerEmail"
                                      :error-messages="errors.collect('installerEmail')"
                                      v-validate="'required'"
                                      data-vv-as="Installatør e-mail"
                                      required disabled></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Installatør tlf.</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field label="Installatør tlf."
                                      name="installerPhone"
                                      v-model="register.installerPhone"
                                      :error-messages="errors.collect('installerPhone')"
                                      v-validate="'required'"
                                      data-vv-as="Installatør tlf."
                                      required disabled></v-text-field>
                    </v-flex>
                </v-layout>
                <div class="installinfo"></div>

            </template>

            <v-alert color="success" class="my-3" icon="check_circle" v-model="success.show">
                {{success.message}}
            </v-alert>

            <v-alert color="error" class="my-3" icon="warning" v-model="error.show">
                {{error.message}}
            </v-alert>

            <v-btn class="opretKort" @click="registerInstallation()" :loading="loading" :disabled="loading"><i class="fa fa-check" aria-hidden="true"></i> Opret garantikort</v-btn>

        </form>

    </div>
</template>

<script>
    export default {
        data: () => ({
            date: null,
            menu: false,
            profile: null,
            register: null,
            loading: false,
            success: {
                show: false,
                message: 'Tak for din henvendelse. Du vil snarest kunne se din registrering under "Installationer".'
            },
            error: {
                show: false,
                message: "Der opstod en fejl. Prøv igen eller kontakt HS Tarm på info@hstarm.dk."
            }
        }),
        components: {},
        computed: {

        },
        methods: {
            addProduct() {
                this.register.lines.push({
                    serial: null,
                    type: null,
                    productNumber: null
                });
            },
            removeProduct(i) {
                this.register.lines.splice(this.register.lines.length - 1, 1);
            },
            initRegister() {
                this.register = {
                    date: null,
                    lines: [{
                        serial: null,
                        type: null,
                        productNumber: null
                    }],
                    owner: null,
                    address: null,
                    zipCode: null,
                    city: null,
                    phone: null,
                    cellphone: null,
                    points: null,
                    installer: null,
                    installerEmail: null,
                    installerPhone: null
                };

                this.errors.clear();
                this.$validator.reset();
            },
            installerInfo() {
                this.$http.get("/api/profile").then(x => {
                    this.profile = x.data;

                    this.register.installer = x.data.firma;
                    this.register.installerEmail = x.data.email;
                    this.register.installerPhone = x.data.firmaTelefon;

                    this.errors.clear();
                    this.$validator.reset();
                });
            },
            registerInstallation() {
                this.$validator.validateAll().then(result => {

                    if (result === true) {
                        this.loading = true;

                        this.error.show = false;
                        this.success.show = false;

                        this.$http.post('/api/profile/registerinstallation', this.register).then(x => {
                            if (x.data.error) {
                                this.error.show = true;
                            } else {
                                this.success.show = true;
                                this.initRegister();
                                this.installerInfo();

                                this.errors.clear();
                                this.$validator.reset();
                            }

                            this.loading = false;
                        }).catch(err => {
                            this.loading = false;
                        });
                    } else {

                    }
                });

            },
            checkSerial(line, key) {
                this.loading = true;

                this.$http.get("/api/profile/checkserial", {
                    params: { serial: line.serial }
                }).then(x => {
                    if (x.data.error) {
                        this.errors.add({
                            field: key,
                            msg: x.data.error
                        })
                    } else {
                        line.serial = x.data.serial;
                        line.productNumber = x.data.productNumber;
                        line.type = x.data.type;
                    }

                    this.loading = false;
                });
            }
        },
        created() {
            this.installerInfo();
            this.initRegister();
        },
        mounted() {

        }
    }
</script>
