<template>
    <div class="totalPadding">
        <div class="topline">
            <h1><span>Reservedele</span></h1>
            <div class="searching">
                <form action="/spareparts/search" method="get">
                    <input type="text" name="q" placeholder="Søg efter reservedel">
                    <button>
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>
        <v-layout row wrap justify-center>
            <v-flex xs12 sm6 md4 lg2 v-for="item in categories" key="item.id">
                <router-link :to="{ name: 'spareparts-category', params: { category: item.url }}">
                    <div class="cBox">
                        <img class="ikon" :src="'/img/categories/' + item.code + '.png'" />
                        <span>{{item.name}}</span>
                        <img class="produktvis" :src="'/img/categories/' + item.code + 'thumb.jpg'" />
                        <div class="selectThis">
                            Vælg kategori
                        </div>
                    </div>
                </router-link>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        data: () => ({
            categories: []
        }),
        components: {},
        computed: {

        },
        methods: {

        },
        created() {
            this.$http("/api/spareparts/categories").then(x => {
                this.categories = x.data;
            });
        },
        mounted() {

        }
    }
</script>
