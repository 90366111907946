<template>
    <article>
        <div class="loginNow">
            <LoginForm v-if="login" />
            <RegisterForm v-if="register" />
        </div>
    </article>
</template>

<script>
    import LoginForm from './Components/LoginForm.vue';
    import RegisterForm from './Components/RegisterForm.vue';

    export default {
        data: () => ({
            login: true,
            register: false
        }),
        components: { LoginForm, RegisterForm },
        watch: {
            "$route"() {
                if (this.$route.name == "login") {
                    this.login = true;
                    this.register = false;
                } else {
                    this.login = false;
                    this.register = true;
                }
            }
        },
        computed: {

        },
        methods: {

        },
        created() {
            if (this.$route.name == "login") {
                this.login = true;
                this.register = false;
            } else {
                this.login = false;
                this.register = true;
            }
        },
        mounted() {

        }
    }
</script>