<template>
    <div>
        <v-tabs>
            <v-tabs-bar class="underKategorier">
                <v-tabs-item v-for="item in productGroups" key="item.id" :to="{ name: 'productGroup', params: { category: $route.params.category, productGroup: item.url }}">
                    {{item.name}}
                </v-tabs-item>

                <v-tabs-item :to="{ name: 'exhausts', params: { category: $route.params.category } }" class="exhaustTab" v-if="$route.params.category == 'gas' || $route.params.category == 'olie'">
                    Aftræk
                </v-tabs-item>
            </v-tabs-bar>
        </v-tabs>
        <div class="topline">
            <h1>
                <router-link to="/products">Produkter</router-link><span>/</span>
                <router-link v-if="getCategory" :to="'/products/' + getCategory.url">{{getCategory.name}}</router-link>
                <template v-if="getProductGroup">
                    <span>/</span>{{getProductGroup.name}}
                </template>
            </h1>

            <div class="searching">
                <form action="/products/search" method="get" @submit.prevent="getProducts(); getProductFilters();">
                    <select @change="getProducts(); getProductFilters();" v-for="filter in productFilters" :placeholder="filter.name" :name="filter.code" v-model="search.filters[filter.code]">
                        <option :value="undefined">{{filter.name}}</option>
                        <option v-for="(option, oKey) in filter.values" :value="option">{{filter.texts[oKey]}}</option>
                    </select>

                    <input type="text" v-model="search.query" name="q" placeholder="Søg efter produkt">
                    <button>
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>
        <v-layout row wrap>
            <v-flex sm6 md4 lg4 xl3 v-for="item in products" key="item.id">
                <div class="produktBox">
                    <router-link :to="{ name: 'product', params: { category: $route.params.category, productGroup: item.productGroup, product: item.no  } }">
                        <img :src="item.image" />
                    </router-link>
                    <div class="pBoxContent">
                        <!--<span class="varegroup">{{item.varegruppe}}</span>-->
                        <span class="id">{{item.no}}</span>
                        <h4>{{item.name}}</h4>
                        <!--<span v-if="item.subsidyEligible" class="addEnergyLabel"><i class="fa fa-leaf" aria-hidden="true"></i> Godkendt til energitilskud</span>-->
                        <!--<div class="mb-2">VVS-nummer: {{item.vvsNumber}}</div>-->
                        <h4><strong>DKK {{localNumber(item.discountedPrice)}}</strong> <small v-if="item.price > item.discountedPrice"><del>{{localNumber(item.price)}}</del> <span>({{getDiscount(item.price, item.discountedPrice)}}%)</span></small></h4>
                        <router-link class="goToProduct" :to="{ name: 'product', params: { category: $route.params.category, productGroup: item.productGroup, product: item.no  } }">
                            <i class="fa fa-info" aria-hidden="true"></i> Læs mere
                        </router-link>
                        <button class="quickBasket" @click="addToBasket(item.no, $event)">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="errorMessage = null">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            productGroups: [],
            products: [],
            productFilters: [],
            error: {
                show: false,
                message: ''
            },
            search: {
                query: null,
                filters: {}
            }
        }),
        props: ["categories"],
        watch: {
            '$route'(to, from) {
                if (from.name == "category" || from.name == "productGroup") {
                    this.getProductGroups();
                    this.getProducts();
                    this.getProductFilters();
                }
            }
        },
        components: {},
        computed: {
            getCategory() {
                return this.categories.find(x => x.url == this.$route.params.category);
            },
            getProductGroup() {
                return this.productGroups.find(x => x.url == this.$route.params.productGroup);
            }
        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            getDiscount(val1, val2) {
                return this.localNumber((val1 - val2) / val1 * 100);
            },
            getProductGroups() {
                this.$http("/api/productgroups", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            getProducts() {
                this.$http.post("/api/products", this.search, {
                    params: {
                        category: this.$route.params.category,
                        productGroup: this.$route.params.productGroup
                    }
                }).then(x => {
                    this.products = x.data;
                });
            },
            getProductFilters() {
                this.$http.post("/api/product-filters", this.search, {
                    params: {
                        category: this.$route.params.category,
                        productGroup: this.$route.params.productGroup
                    }
                }).then(x => {
                    this.productFilters = x.data;
                });
            },
            addToBasket(no, e) {
                this.$http("/api/checkout/add", {
                    params: {
                        productNumber: no,
                        quantity: 1
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        this.$store.dispatch('getMiniBasket');

                        $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        setTimeout(() => {
                            $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        }, 1500);
                    }
                });
            }
        },
        created() {
            if (this.$route.query.q) {
                this.search.query = this.$route.query.q;
            }

            this.getProductGroups();
            this.getProducts();
            this.getProductFilters();
        },
        mounted() {

        }
    }
</script>
