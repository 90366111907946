<template>
    <div>
        <div>
            <div v-if="product">
                <v-tabs>
                    <v-tabs-bar class="underKategorier">
                        <v-tabs-item v-for="item in productGroups" key="item.id" :to="{ name: 'productGroup', params: { category: $route.params.category, productGroup: item.url }}">
                            {{item.name}}
                        </v-tabs-item>
                    </v-tabs-bar>
                </v-tabs>
                <div class="topline">
                    <h1>
                        <router-link to="/products">Produkter</router-link><span>/</span>
                        <router-link v-if="getCategory" :to="'/products/' + getCategory.url">{{getCategory.name}}</router-link>
                        <router-link v-if="getProductGroup" :to="'/products/' + getCategory.url + '/' + getProductGroup.url"><span>/</span>{{getProductGroup.name}}</router-link>
                        <span>/</span> {{product.name}}
                    </h1>

                    <!--<div class="searching">
                        <form :action="'/products/' + $route.params.category" method="get">
                            <input type="text" name="q" placeholder="Søg efter produkt">
                            <button>
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </form>
                    </div>-->
                </div>
                <v-layout wrap row>
                    <v-flex xs12 sm6 md4 lg3>
                        <div class="pageProductBox">
                            <img :src="product.image" />
                            <div class="pBoxContent">
                                <h4>{{product.name}}</h4>
                                <h4><strong>DKK {{localNumber(product.discountedPrice)}}</strong> <small v-if="product.price > product.discountedPrice"><del>{{localNumber(product.price)}}</del> <span>({{getDiscount(product.price, product.discountedPrice)}}%)</span></small></h4>
                                <span class="id">Varenummer: {{product.no}}</span>
                                <!--<span class="varegroup">VVS-nummer: {{product.vvsNumber}}</span>-->
                            </div>
                            <div class="pBoxStatus">
                                <template v-if="$moment().isBefore('2020-01-02')">
                                    <span><i class="fa fa-truck"></i> Kan afsendes efter nytår</span>
                                </template>
                                <template v-else>
                                    <span v-if="product.disponibel > 0"><i class="fa fa-check-circle" aria-hidden="true"></i> På lager</span>
                                    <span v-if="product.disponibel < 1"><i class="fa fa-times-circle red--text" aria-hidden="true"></i> Ikke på lager</span>
                                    <span v-if="product.disponibel > 0"><i class="fa fa-truck" aria-hidden="true"></i> {{product.afsendes}}<br />{{product.kritiskText}}</span>
                                </template>
                                <span class="greenColor" v-if="product.subsidyEligible"><i class="fa fa-leaf" aria-hidden="true"></i> Godkendt til energitilskud</span>
                            </div>
                            <div v-if="product.disponibel > 0" class="pBoxAdd">
                                <span><input type="number" v-model="product.quantity" /> Antal</span>
                                <button type="submit" @click="addToBasket(product.no, product.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm6 md8 lg9>
                        <v-tabs v-model="activeTab">
                            <v-tabs-bar class="productPageTabs">
                                <v-tabs-item @click="activeTab = 'accessories'" :href="'#accessories'" ripple v-if="accessoriesTab">
                                    Tilbehør
                                </v-tabs-item>
                                <v-tabs-item @click="assembly = null;" :href="'#assemblies'" ripple v-if="assembliesTab">
                                    Installationsforslag
                                </v-tabs-item>
                                <v-tabs-item @click="exhaust = null;" :href="'#exhausts'" ripple v-if="exhaustTab">
                                    Aftræk
                                </v-tabs-item>
                                <v-tabs-item :href="'#datablade'" ripple v-if="databladeTab">
                                    Datablade
                                </v-tabs-item>
                                <v-tabs-item :href="'#manualer'" ripple v-if="manualerTab">
                                    Manualer
                                </v-tabs-item>
                                <v-tabs-item :href="'#brochurer'" ripple v-if="brochurerTab">
                                    Brochurer
                                </v-tabs-item>
                                <!--<v-tabs-item :href="'#addenergy'" ripple v-if="product.subsidyEligible">
                                    Energitilskud
                                </v-tabs-item>-->
                                <v-tabs-item :href="'#energysolution'" ripple v-if="product.subsidyEligible">
                                    Energitilskud
                                </v-tabs-item>
                            </v-tabs-bar>
                            <v-tabs-items>
                                <v-tabs-content id="accessories">
                                    <v-layout wrap row>
                                        <v-flex xs12 sm12 md6 lg4 xl4 v-if="accessories" v-for="item in accessories" :key="item.no">
                                            <ul class="produktEkstra">
                                                <li>
                                                    <img :src="item.image" />

                                                </li>
                                                <li>
                                                    <span class="varenummer">{{item.no}}</span><br />
                                                    <span class="produktnavn">{{item.name}}</span>
                                                </li>

                                                <li class="addEkstra">
                                                    <span class="produktpris">
                                                        <strong>DKK {{localNumber(item.discountedPrice)}}</strong>
                                                        <small v-if="item.price > item.discountedPrice">
                                                            <del>{{localNumber(item.price)}}</del> ({{getDiscount(item.price, item.discountedPrice)}}%)
                                                        </small>
                                                    </span>
                                                    <span>Antal <input type="number" v-model="item.quantity" /></span>
                                                </li>
                                                <li class="addEkstra">
                                                    <button type="submit" @click="addToBasket(item.no, item.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                                                    <router-link class="quickBasket orange" tag="button" :to="{ name: 'accessory', params: { product: item.no }}">Se mere <i class="fa fa-info" aria-hidden="true"></i></router-link>

                                                </li>
                                            </ul>
                                        </v-flex>
                                    </v-layout>
                                    <div class="pageLoad" v-if="!accessories">
                                        <div class="spinner"></div>
                                    </div>
                                </v-tabs-content>
                                <v-tabs-content id="assemblies">
                                    <v-layout wrap row>
                                        <v-flex xs12 sm12 md6 lg4 xl4 v-if="!assembly" v-for="item in assemblies" :key="item.id">
                                            <ul class="produktEkstra">
                                                <li>
                                                    <img :src="item.path" />
                                                </li>
                                                <li>
                                                    <span class="varenummer">{{item.no}}</span><br />
                                                    <span class="produktnavn">{{item.text}}</span>

                                                </li>
                                                <li class="addEkstra">
                                                    <button type="button" class="quickBasket" @click="assembly = item">Se mere <i class="fa fa-info" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </v-flex>
                                    </v-layout>
                                    <div v-if="assembly">
                                        <img :src="assembly.path" />

                                        <div class="produktEkstra assemblyPackage mb-5">
                                            <ul>
                                                <li>
                                                    <img :src="assembly.path" />
                                                </li>
                                                <li>
                                                    <span class="varenummer">{{assembly.no}}</span><br />
                                                    <span class="produktnavn">{{assembly.text}}</span>

                                                </li>
                                                <li>
                                                    <span class="produktpris">
                                                        <strong>DKK {{localNumber(assembly.totalDiscountedPrice)}}</strong>
                                                        <small v-if="assembly.price > assembly.discountedPrice">
                                                            <del>{{localNumber(assembly.totalPrice)}}</del> ({{getDiscount(assembly.totalPrice, assembly.totalDiscountedPrice)}}%)
                                                        </small>
                                                    </span>
                                                </li>
                                                <li class="addEkstra">
                                                    <button type="submit" @click="addToBasket(assembly.no, assembly.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>

                                                </li>
                                            </ul>
                                        </div>
                                        <v-layout row wrap>
                                            <v-flex xs12 sm12 md6 lg4 xl4 class="assemblyItems" v-for="item in assembly.items" :key="item.no">
                                                <ul class="produktEkstra">
                                                    <li class="position">
                                                        <span class="circle">{{item.position}}</span>
                                                    </li>
                                                    <li>
                                                        <img :src="item.image" />
                                                    </li>
                                                    <li>
                                                        <span class="varenummer">{{item.no}}</span><br />
                                                        <span class="produktnavn">{{item.name}}</span>

                                                    </li>
                                                    <li class="addEkstra">
                                                        <span class="produktpris">
                                                            <strong>DKK {{localNumber(item.discountedPrice)}}</strong>
                                                            <small v-if="item.price > item.discountedPrice">
                                                                <del>{{localNumber(item.price)}}</del> ({{getDiscount(item.price, item.discountedPrice)}}%)
                                                            </small>
                                                        </span>
                                                        <span>Antal <input type="number" v-model="item.quantity" /></span>

                                                    </li>
                                                    <li class="addEkstra">
                                                        <button type="submit" @click="addToBasket(item.no, item.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                                                    </li>
                                                </ul>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-tabs-content>
                                <v-tabs-content id="exhausts">
                                    <v-layout wrap row>
                                        <v-flex xs12 sm12 md6 lg4 xl4 v-if="!exhaust" v-for="item in exhausts" :key="item.id">
                                            <ul class="produktEkstra">
                                                <li>
                                                    <img :src="item.path" />
                                                </li>
                                                <li>
                                                    <span class="varenummer">{{item.no}}</span><br />
                                                    <span class="produktnavn">{{item.text}}</span>

                                                </li>
                                                <li class="addEkstra">
                                                    <button type="button" class="quickBasket" @click="selectExhaust(item)">Se mere <i class="fa fa-info" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </v-flex>
                                    </v-layout>
                                    <div v-if="exhaust">
                                        <img :src="exhaust.path" />

                                        <div class="produktEkstra assemblyPackage mb-5" v-if="false">
                                            <ul>
                                                <li>
                                                    <img :src="exhaust.path" />
                                                </li>
                                                <li>
                                                    <span class="varenummer">{{exhaust.no}}</span><br />
                                                    <span class="produktnavn">{{exhaust.text}}</span>
                                                </li>
                                                <li>
                                                    <span class="produktpris">
                                                        <!--<strong>DKK {{localNumber(assembly.totalDiscountedPrice)}}</strong>
                                                        <small v-if="assembly.price > assembly.discountedPrice">
                                                            <del>{{localNumber(assembly.totalPrice)}}</del> ({{getDiscount(assembly.totalPrice, assembly.totalDiscountedPrice)}}%)
                                                        </small>-->
                                                    </span>
                                                </li>
                                                <li class="addEkstra">
                                                    <button type="submit" @click="addToBasket(exhaust.no, exhaust.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <v-layout row wrap class="package">
                                            <v-flex xs12>
                                                <div class="produktEkstra listReserve">
                                                    <v-btn dark class="orange darken-1 packageButton" @click="packageActive = true;"
                                                           v-if="!packageActive">Dan pakke<span class="fa fa-archive ml-3"></span></v-btn>
                                                    <v-btn dark class="green packageButton" @click="buyPackage" :loading="packageLoading"
                                                           v-if="packageActive">Læg pakke i kurv<span class="fa fa-shopping-cart ml-3"></span></v-btn>
                                                    <v-btn dark class="red packageButton" @click="package = []; packageActive = false;"
                                                           :loading="packageLoading" v-if="packageActive">Annuller pakke<span class="fa fa-trash ml-3"></span></v-btn>

                                                    <span style="display: inline-block; padding: 12px 12px 0px;" v-if="!packageActive">
                                                        Tryk på "DAN PAKKE" for at samle alle dele til aftrækket, før de samlet lægges i kurven
                                                    </span>

                                                    <span style="display: inline-block; padding: 12px 12px 0px;" v-if="packageActive">
                                                        Vælg varer fra nedenstående liste til pakken. Når alle nødvendige dele og antal er lagt i pakken, trykkes på "LÆG PAKKE I KURV"
                                                    </span>
                                                </div>
                                            </v-flex>

                                            <v-flex xs12 sm12 md6 lg4 xl4 class="assemblyItems" v-for="item in exhaust.items" :key="item.no">
                                                <ul class="produktEkstra listReserve">
                                                    <li class="position">
                                                        <span class="circle">{{item.position}}</span>
                                                    </li>
                                                    <li>
                                                        <img :src="item.image" />
                                                    </li>
                                                    <li>
                                                        <span class="varenummer">{{item.no}}</span><br />
                                                        <span class="produktnavn">{{item.name}}</span>

                                                    </li>
                                                    <li class="addEkstra">
                                                        <span class="produktpris">
                                                            <strong>DKK {{localNumber(item.discountedPrice)}}</strong>
                                                            <small v-if="item.price > item.discountedPrice">
                                                                <del>{{localNumber(item.price)}}</del> ({{getDiscount(item.price, item.discountedPrice)}}%)
                                                            </small>
                                                        </span>
                                                        <span v-if="packageActive && package.find(x => x.no == item.no)">Antal <input type="number" v-model="package.find(x => x.no == item.no).q" /></span>
                                                        <span v-else>Antal <input type="number" v-model="item.quantity" /></span>
                                                    </li>
                                                    <li class="addEkstra">
                                                        <button v-if="!packageActive" type="submit" @click="addToBasket(item.no, item.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                                                        <button v-if="packageActive && !package.find(x => x.no == item.no)" type="submit" @click="addToPackage(item.no, item.quantity)" class="quickBasket">Vælg til pakke <i class="fa fa-archive" aria-hidden="true"></i></button>
                                                        <button v-if="packageActive && package.find(x => x.no == item.no)" type="submit" @click="removeFromPackage(item.no)" class="quickBasket orange">Fjern fra pakke <i class="fa fa-archive" aria-hidden="true"></i></button>
                                                    </li>
                                                </ul>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-tabs-content>
                                <v-tabs-content id="datablade">
                                    <div v-for="item in datablade" :key="item.id">
                                        <a :href="item.path" class="databladItem"><i class="fa fa-file-text-o" aria-hidden="true"></i> {{item.name}}</a>
                                    </div>
                                </v-tabs-content>
                                <v-tabs-content id="manualer">
                                    <div v-for="item in manualer" :key="item.id">
                                        <a :href="item.path" class="databladItem"><i class="fa fa-file-text-o" aria-hidden="true"></i> {{item.name}}</a>
                                    </div>
                                </v-tabs-content>
                                <v-tabs-content id="brochurer">
                                    <div v-for="item in brochurer" :key="item.id">
                                        <a :href="item.path" class="databladItem"><i class="fa fa-file-text-o" aria-hidden="true"></i> {{item.name}}</a>
                                    </div>
                                </v-tabs-content>
                                <!--<v-tabs-content id="addenergy" v-if="product.subsidyEligible">
                                    <div>
                                        <h3><strong>Få et energitilskud med AddEnergy</strong></h3>
                                        <p><strong>HS Tarm A/S samarbejder med energirådgivningsfirmaet AddEnergy omkring indhentning af energitilskud i forbindelse med udførsel af energiforbedringer i private boliger og erhverv udført af vores kunder.</strong></p>
                                        <p>Som kunde ved HS Tarm A/S er det således muligt i samarbejde med sin egne kunder, at sikre et økonomisk tilskud ifm. energiforbedrende installationer.</p>
                                        <p>
                                            Tilskud kan opnås inden for bl.a. disse energiforbedringer:
                                            <ul>
                                                <li>
                                                    Udskiftning af kedler
                                                </li>
                                                <li>
                                                    Etablering af luft-vand varmepumper og jordvarmepumper
                                                </li>
                                                <li>
                                                    Etablering af luft-luft varmepumper som supplerende opvarmning
                                                </li>
                                            </ul>
                                        </p>
                                        <p>Ønsker du yderligere information omkring energibesparelser og tilskud på energistyrelsens hjemmeside, klik <a target="_blank" href="https://ens.dk/sites/ens.dk/files/Energibesparelser/energiforbedring_bolig.pdf"><strong>her</strong></a>.</p>
                                        <br />
                                        <h3><strong>Tilskuddets størrelse</strong></h3>
                                        <p>Tilskuddets størrelse er afhængig af standardværdier og regelsæt fastsat af Energistyrelsen. Bygningens størrelse, alder og primære energikilde har også betydning for tilskuddets størrelse.</p>
                                        <p>AddEnergy er underlagt energiselskabernes endelige accept og godkendelse.</p>
                                        <p>Der er hele tiden nye regler i lovgivningen eller f.eks. ændringer i tilskudstakster. Det betyder, at energibesparelsen kan blive justeret ved endelig indberetning. Energitilskuddet udbetales altid ud fra senest gældende regelsæt og indberetningsdagens markedspris.</p>
                                        <br />
                                        <h3><strong>Ansøgning af tilskud</strong></h3>
                                        <p>Når der skal søges tilskud til en energiforbedring, er der krav om dokumentation, som er fastsat ved lovgivning. Det eneste man i første omgang skal gøre, er at oprette en elektronisk ansøgning og indtaste de påkrævede oplysninger – indenfor få minutter oplyses det aktuelle tilskudsbeløb.</p>
                                        <p>For at søge tilskud, skal du oprettes som bruger. Tryk <a target="_blank" href="https://addenergy.dk/kontakt/"><strong>her</strong></a> for oprettelse. Er du allerede oprettet, tryk <a target="_blank" href="https://addenergy.boligservicebogen.dk/login"><strong>her</strong></a>.</p>
                                        <p>
                                            De dokumentationskrav der gør sig gældende ifm. ansøgning, omhandler:
                                            <ul>
                                                <li>
                                                    Oplysninger omkring bolig/bygning inkl. billeddokumentation
                                                </li>
                                                <li>
                                                    Oplysninger om eksisterende installation inkl. billeddokumentation
                                                </li>
                                                <li>
                                                    Oplysninger om den/de energiforbedrende tiltag inkl. billeddokumentation
                                                </li>
                                            </ul>
                                        </p>
                                        <p>Det er <strong>vigtigt</strong> at man ansøger <strong>før</strong> arbejdet påbegyndes. Ér arbejdet påbegyndt, frafalder muligheden for tilskud.</p>

                                        <p>Ved behov for rådgivning og spørgsmål omkring energitilskud kan AddEnergy kontaktes. Se kontaktoplysninger <a target="_blank" href="https://addenergy.dk/kontakt/"><strong>her</strong></a>.</p>
                                        <br/>
                                        <a href="https://addenergy.dk/" target="_blank"><img src="/img/addenergy-logo.png" /></a>

                                    </div>
                                </v-tabs-content>-->
                                <v-tabs-content id="energysolution" v-if="product.subsidyEligible">
                                    <div>
                                        <h3><strong>Få et energitilskud med EnergySolution</strong></h3>
                                        <a href="https://energysolution.dk/" target="_blank"><img style="margin:20px 0;" width="300" src="/img/energysolution.png" /></a>
                                        <p>
                                            Vidste du, at du kan få energitilskud, når du køber en varmepumpe? Få op til 3.700 kr. ved køb af en luft/luft varmepumpe og op til 9.200 kr. ved køb af en luft/vand varmepumpe.
                                        </p>
                                        <p>
                                            Hvis du har prøvet at søge energitilskud før, kan du anmode om at blive oprettet som aktør <a href="https://privatbesparelser.dk/hs-tarm/bliv-aktoer" target="_blank"><strong>her</strong></a>. Du får et lidt højere tilskud, men til gengæld står du selv for hele ansøgningsprocessen.
                                            Alternativt kan du benytte nedenstående formular, så varetager vi hele ansøgningsprocessen fra start til slut.
                                        </p>
                                        <p>
                                            Udbydes i samarbejde med EnergySolution A/S. Ring på <a href="tel:70404101">70 404 101</a> eller skriv til <a href="mailto:kundeservice@privatbesparelser.dk">kundeservice@privatbesparelser.dk</a> for hjælp.
                                        </p>
                                        <br/>
                                        <iframe src="https://hs-tarm.privatbesparelser.dk" width="100%" height="600px" frameborder="0"></iframe>
                                    </div>
                                </v-tabs-content>
                            </v-tabs-items>
                        </v-tabs>
                    </v-flex>
                </v-layout>
            </div>
            <div class="pageLoad" v-if="!product">
                <div class="spinner"></div>
            </div>
        </div>

        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="errorMessage = null">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            activeTab: 'accessories',
            product: null,
            productGroups: [],
            accessories: null,
            datablade: [],
            manualer: [],
            brochurer: [],
            assemblies: [],
            exhausts: [],
            assembly: null,
            exhaust: null,
            spareparts: [],
            sparepart: null,
            error: {
                show: false,
                message: ''
            },
            assembliesTab: true,
            databladeTab: true,
            manualerTab: true,
            brochurerTab: true,
            accessoriesTab: true,
            serviceTab: true,
            exhaustTab: true,
            package: [],
            packageActive: false,
            packageLoading: false
        }),
        props: ["categories"],
        components: {},
        computed: {
            getCategory() {
                return this.categories.find(x => x.url == this.$route.params.category);
            },
            getProductGroup() {
                return this.productGroups.find(x => x.url == this.$route.params.productGroup);
            }
        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            getDiscount(val1, val2) {
                return this.localNumber((val1 - val2) / val1 * 100);
            },
            getProductGroups() {
                this.$http("/api/productgroups", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            getProduct() {
                this.$http("/api/product", {
                    params: {
                        productNumber: this.$route.params.product
                    }
                }).then(x => {
                    this.product = x.data;
                });
            },
            getAccessories() {
                this.$http("/api/product/accessories", {
                    params: {
                        productNumber: this.$route.params.product
                    }
                }).then(x => {
                    this.accessories = x.data;

                    this.accessoriesTab = this.accessories && this.accessories.length > 0;
                });
            },
            getDatablade() {
                this.$http("/api/product/documents", {
                    params: {
                        productNumber: this.$route.params.product
                    }
                }).then(x => {
                    this.datablade = x.data.filter(x => x.documentType == 7)
                    this.manualer = x.data.filter(x => x.documentType == 4)
                    this.brochurer = x.data.filter(x => x.documentType == 3 || x.documentType == 11)

                    this.databladeTab = this.datablade && this.datablade.length > 0;
                    this.manualerTab = this.manualer && this.manualer.length > 0;
                    this.brochurerTab = this.brochurer && this.brochurer.length > 0;
                });
            },
            getAssemblies() {
                this.$http("/api/product/assemblies", {
                    params: {
                        productNumber: this.$route.params.product
                    }
                }).then(x => {
                    this.assemblies = x.data;

                    this.assembliesTab = this.assemblies && this.assemblies.length > 0;
                });
            },
            getExhausts() {
                this.$http("/api/product/exhausts", {
                    params: {
                        productNumber: this.$route.params.product
                    }
                }).then(x => {
                    this.exhausts = x.data;

                    this.exhaustTab = this.exhausts && this.exhausts.length > 0;
                });
            },
            selectExhaust(item) {
                this.exhaust = item;

                this.$http("/api/product/exhaustitems", {
                    params: {
                        productNumber: this.$route.params.product,
                        drawing: item.no
                    }
                }).then(x => {
                    this.exhaust.items = x.data;
                });
            },
            addToBasket(no, q, e) {
                this.$http("/api/checkout/add", {
                    params: {
                        productNumber: no,
                        quantity: q
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        this.$store.dispatch('getMiniBasket');

                        $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        setTimeout(() => {
                            $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        }, 1500);
                    }
                });
            },
            addToPackage(no, q, e) {
                var items = this.package;
                var item = items.find(x => x.no == no);

                if (item) {
                    q += item.q;
                }

                this.$http("/api/product/package/add", {
                    params: {
                        productNumber: no,
                        quantity: q
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        if (item) {
                            item.q = q;
                            this.package = items;
                        } else {
                            this.package.push({ no: no, q: q });
                        }
                    }
                });
            },
            removeFromPackage(no) {
                var item = this.package.indexOf(this.package.find(x => x.no == no));
                if (item > -1) {
                    this.package.splice(item, 1);
                }
            },
            buyPackage() {
                var loader = 0;
                for (var i = 0; i < this.package.length; i++) {
                    var item = this.package[i];
                    this.packageLoading = true;

                    this.$http("/api/checkout/add", {
                        params: {
                            productNumber: item.no,
                            quantity: item.q
                        }
                    }).then(x => {
                        loader++;

                        if (x.data.error) {
                            this.error.show = true;
                            this.error.message = x.data.error;
                        } else {
                            this.$store.dispatch('getMiniBasket');
                        }

                        if (loader >= this.package.length) {
                            this.packageLoading = false;
                            this.packageActive = false;
                            this.package = [];
                        }
                    });
                }
            }
        },
        created() {
            this.getProductGroups();
            this.getProduct();
            this.getAccessories();
            this.getDatablade();
            this.getAssemblies();
            this.getExhausts();
        },
        mounted() {

        }
    }
</script>
