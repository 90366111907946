<template>
    <div>
        <div class="topline">
            <h1>Min side <span>/</span> <span>Flammepoint</span></h1>
        </div>
        <div class="pageLoad" v-if="loading">
            <div class="spinner"></div>
        </div>

        <v-layout row wrap>
            <v-flex xs12 v-if="points">
                <div class="produktEkstra ">
                    <span class="d-inline-block mr-5"><strong>Optjente point:</strong> {{localNumber(points.earned)}}</span>
                    <span class="d-inline-block mr-5"><strong>Forbrugte point:</strong> {{localNumber(points.spend)}}</span>
                    <span class="d-inline-block mr-5"><strong>Nuværende saldo:</strong> {{localNumber(points.total)}}</span>
                </div>

                <div class="produktEkstra mt-2">
                    <v-btn :href="'http://www.partnerstores.dk/hstarm_da/?service=hstarm&uid=' + points.shopAuth" class="colorOrange" target="_blank">Indløs i pointshop</v-btn>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        data: () => ({
            points: null,
            loading: true
        }),
        components: {},
        computed: {

        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(0)).toLocaleString("da", { minimumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        },
        created() {
            this.$http.get("/api/profile/points").then(x => {
                this.points = x.data;
                this.loading = false;
            });
        },
        mounted() {

        }
    }
</script>
