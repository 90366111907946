<template>
    <div>
        <div v-if="sparepart">
            <v-tabs>
                <v-tabs-bar class="underKategorier">
                    <v-tabs-item v-for="item in productGroups" key="item.id" :to="{ name: 'spareparts-productGroup', params: { category: $route.params.category, productGroup: item.url }}">
                        {{item.name}}
                    </v-tabs-item>
                </v-tabs-bar>
            </v-tabs>
            <div class="topline">
                <h1>
                    <router-link to="/spareparts">Reservedele</router-link><span>/</span>
                    <router-link v-if="getCategory" :to="'/spareparts/' + getCategory.url">{{getCategory.name}}</router-link>
                    <router-link v-if="getProductGroup" :to="'/spareparts/' + getCategory.url + '/' + getProductGroup.url"><span>/</span>{{getProductGroup.name}}</router-link>
                    <span>/</span> {{sparepart.text}}
                </h1>
                <div class="searching">
                    <form action="/spareparts/search" method="get">
                        <input type="text" name="q" placeholder="Søg efter reservedel">
                        <button>
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </form>
                </div>
            </div>
            <v-layout wrap row>
                <v-flex xs12>
                    <h3>{{sparepart.text}}</h3>
                    <img :src="sparepart.path" />
                </v-flex>
                <v-flex xs12>
                    <v-layout row wrap>
                        <v-flex xs12 sm12 md6 lg4 xl3 v-for="item in sparepart.spareParts" :key="item.id">
                            <ul class="produktEkstra listReserve">
                                <li class="position">
                                    <span class="circle">{{item.position}}</span>
                                </li>
                                <li>
                                    <img :src="item.image" />
                                </li>
                                <li>
                                    <span class="varenummer">{{item.no}}</span>
                                </li>
                                <li>
                                    <span class="produktnavn">{{item.name}}</span>
                                </li>
                                <li>
                                    <span class="produktpris">
                                        <strong>DKK {{localNumber(item.discountedPrice)}}</strong>
                                        <small v-if="item.price > item.discountedPrice">
                                            <del>{{localNumber(item.price)}}</del> <span>({{getDiscount(item.price, item.discountedPrice)}}%)</span>
                                        </small>
                                    </span>
                                </li>
                                <li class="addEkstra">
                                    <span>Antal <input type="number" v-model="item.quantity" /></span>
                                    <button class="quickBasket" @click="addToBasket(item.no, item.quantity, $event)">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                                    <router-link class="quickBasket orange" tag="button" :to="{ name: 'sparepart', params: { category: $route.params.category, productGroup: $route.params.productGroup, sparepart: $route.params.sparepart, product: item.no }}">Se mere <i class="fa fa-info" aria-hidden="true"></i></router-link>
                                </li>
                            </ul>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </div>
        <div class="pageLoad" v-if="!sparepart">
            <div class="spinner"></div>
        </div>

        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="errorMessage = null">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            activeTab: 'accessories',
            product: null,
            productGroups: [],
            sparepart: null,
            error: {
                show: false,
                message: ''
            }
        }),
        props: ["categories"],
        components: {},
        computed: {
            getCategory() {
                return this.categories.find(x => x.url == this.$route.params.category);
            },
            getProductGroup() {
                return this.productGroups.find(x => x.url == this.$route.params.productGroup);
            }
        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            getDiscount(val1, val2) {
                return this.localNumber((val1 - val2) / val1 * 100);
            },
            getProductGroups() {
                this.$http("/api/spareparts/productgroups", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            getSparePart() {
                this.$http("/api/sparepart", {
                    params: {
                        productNumber: this.$route.params.sparepart
                    }
                }).then(x => {
                    this.sparepart = x.data;
                });
            },
            addToBasket(no, q, e) {
                this.$http("/api/checkout/add", {
                    params: {
                        productNumber: no,
                        quantity: q
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        this.$store.dispatch('getMiniBasket');

                        $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        setTimeout(() => {
                            $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        }, 1500);
                    }
                });
            }
        },
        created() {
            this.getProductGroups();
            this.getSparePart();
        },
        mounted() {

        }
    }
</script>
