<template>
    <div class="filler">
        <v-container>
            <div class="centerIt">
                <img class="loginLogo" src="/img/logo.png" />
                <img class="loginBaxiLogo" src="/img/baxilogo.png" />

                <div class="floatCard">
                    <h2>Log på her</h2>

                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-alert color="success" class="mt-3" icon="check_circle" v-model="success.show">
                                {{success.message}}
                            </v-alert>

                            <v-alert color="error" class="mt-3" icon="warning" v-model="error.show">
                                {{error.message}}
                            </v-alert>
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap>
                        <v-flex xs12 class="text-xs-center mb-3">
                            <form v-on:submit.prevent="submitForm" novalidate class="logOn">
                                <v-text-field name="email" v-model="form.email"
                                              label="E-mail"
                                              :error-messages="errors.collect('email')"
                                              v-validate="'required|email'"
                                              data-vv-as="E-mail"
                                              required></v-text-field>
                                <v-text-field name="password" v-model="form.password"
                                              label="Adgangskode" type="password"
                                              :error-messages="errors.collect('password')"
                                              v-validate="'required'"
                                              data-vv-as="Adgangskode"
                                              required></v-text-field>

                                <v-btn class="loginBtn trical2 mt-3" type="submit" :loading="loading" :disabled="loading">Log på MIT BAXI <i class="fa fa-sign-in" aria-hidden="true"></i></v-btn>
                            </form>
                        </v-flex>
                    </v-layout>


                    <v-layout row wrap>
                        <v-flex xs12 class="text-xs-center pa-3">
                            <router-link class="mx-3 orange--text text--darken-2" to="/register">Opret log ind</router-link>
                            <router-link class="mx-3 orange--text text--darken-2" to="/forgot-password">Glemt adgangskode?</router-link>
                        </v-flex>
                    </v-layout>
                </div>

            </div>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                xsrfToken: null,
                form: {},
                loading: false,
                error: {
                    show: false,
                    message: ''
                },
                success: {
                    show: false,
                    message: "Du bliver viderestillet om få øjeblikke."
                }
            };
        },
        components: {},
        computed: {

        },
        methods: {
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result === true) {
                        this.loading = true;

                        this.error.show = false;
                        this.success.show = false;

                        axios.post('/api/login', this.form, { headers: { 'X-XSRF-TOKEN': this.xsrfToken } }).then(response => {
                            if (response.data.error) {
                                this.error.show = true;
                                this.error.message = response.data.error;
                            } else {
                                this.errors.clear();
                                this.form = {};
                                this.success.show = true;

                                this.$store.dispatch('setLoggedIn', response.data).then(c => {
                                    if (this.$route.query && this.$route.query.ReturnUrl != null) {
                                        window.location = this.$route.query.ReturnUrl;
                                    } else {
                                        window.location = "/";
                                    }
                                });
                            }

                            this.loading = false;

                        }).catch(err => {
                            this.loading = false;
                        });

                        return;
                    } else {

                    }
                });
            }
        },
        created() {

        },
        mounted() {
            this.xsrfToken = $('#xsrfToken').val();
        }
    }
</script>
