<template>
    <v-app light>
        <router-view></router-view>
    </v-app>
</template>

<script>

    export default {
        components: { },
        computed: {
            
        },
        created() {

        },
        mounted() {

        }
    }
</script>