import axios from 'axios';

var PLACEHOLDER_REGEXP = /\{([0-9a-zA-Z]+)\}/g;


export default function extend(Vue) {

    Vue.prototype.$pt = function (key) {
        var titles = this.$store.state.pageTitles;

        if (titles[key] !== null) {
            return titles[key];
        } else {
            return key;
        }
    };

    Vue.prototype.$t = function (key) {
        var local = this.$store.state.local;

        if (local[key] !== null) {
            return local[key];
        } else {
            return key;
        }
    };

    Vue.prototype.$format = function () {
        if (arguments.length === 0) {
            return "";
        } else if (arguments.length === 1) {
            return arguments[0];
        } else {
            var args = arguments;
            var message = args[0];
            return message.replace(PLACEHOLDER_REGEXP, function (match, placeholder, index) {
                if (message[index - 1] === "{" && message[index + match.length] === "}") {
                    return placeholder; // deal with the escaped brackets
                } else {
                    var i = parseInt(placeholder);
                    var result = args[i + 1];
                    if (result === null || result === undefined) {
                        return "";
                    } else {
                        return result;
                    }
                }
            });
        }
    };

}