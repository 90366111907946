<template>
    <div>
        <div class="topline">
            <h1>Min side <span>/</span> <span>Installationer</span></h1>

            <div class="searching">
                <form>
                    <input type="text" v-model="query" name="q" placeholder="Søg efter installation">
                    <button type="button">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>
        <div class="pageLoad" v-if="loading">
            <div class="spinner"></div>
        </div>
        <v-layout row wrap>
            <v-flex xs12 sm4 v-for="item in filteredInstallations" :key="item.contractNumber">
                <div class="produktEkstra fakturaList">
                    <ul>
                        <li>
                            <span class="fakturanummer">{{item.contractNumber}}</span>
                        </li>
                        <li>
                            <span class="produktnavn">{{item.description}}</span>
                        </li>
                        <li class="goRight">
                            <span class="adresse">{{item.address}}, {{item.zipCode}} {{item.city}}</span>
                            <router-link class="seIndhold" :to="{ name: 'installation', params: { id: item.contractNumber } }">Mere info <i class="fa fa-info" aria-hidden="true"></i></router-link>
                        </li>
                    </ul>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        data: () => ({
            installations: [],
            loading: true,
            query: null
        }),
        components: {},
        computed: {
            filteredInstallations() {
                console.log(this.query);
                if (this.query == null || this.query == '') {
                    return this.installations;
                }

                return this.installations.filter(x => 
                    (x.serialNo && x.serialNo.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.contractNumber && x.contractNumber.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.address && x.address.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.city && x.city.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.zipCode && x.zipCode.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.description && x.description.toLowerCase().includes(this.query.toLowerCase()))
                );
            }
        },
        methods: {
        },
        created() {
            this.$http.get("/api/profile/installations").then(x => {
                this.installations = x.data;
                this.loading = false;
            });
        },
        mounted() {

        }
    }
</script>
