import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import VueRouter from 'vue-router';
import VueAnalytics from 'vue-analytics';
import VueScrollTo from 'vue-scrollto';

import da from 'vee-validate/dist/locale/da';
import VeeValidate, { Validator } from 'vee-validate';

import moment from 'moment';
import axios from 'axios';

import store from './Vuex/store';
import routes from './routes';
import extend from './extensions';
import App from './components/App.vue';

//import "./css/custom.scss";
//import "./css/responsive.scss";

Vue.use(Vuetify);
Vue.use(VueRouter);
Validator.localize('da', da);
Vue.use(VeeValidate, {
    locale: 'da'
});

extend(Vue);
moment.locale("da");

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

Vue.use(VueAnalytics, {
    id: 'UA-75470211-2',
    router
});

const app = new Vue({
    store,
    router,
    ...App
});


axios.get("/api/init").then(x => {
    store.replaceState({ ...store.state, ...x.data });
})


app.$mount('#app');
