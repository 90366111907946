<template>
    <div>
        <div>
            <div v-if="product">
                <v-tabs v-if="productGroups.length > 0">
                    <v-tabs-bar class="underKategorier">
                        <v-tabs-item v-for="item in productGroups" key="item.id" :to="{ name: 'spareparts-productGroup', params: { category: $route.params.category, productGroup: item.url }}">
                            {{item.name}}
                        </v-tabs-item>
                    </v-tabs-bar>
                </v-tabs>
                <div class="topline">
                    <h1>
                        <router-link to="/spareparts">Reservedele</router-link> <span>/</span>
                        <router-link v-if="getCategory" :to="'/spareparts/' + getCategory.url">{{getCategory.name}}</router-link>
                        <router-link v-if="getProductGroup" :to="'/spareparts/' + getCategory.url + '/' + getProductGroup.url"><span>/</span>{{getProductGroup.name}}</router-link>
                        <router-link v-if="product.drawing && product.drawing.no" :to="'/spareparts/' + getCategory.url + '/' + getProductGroup.url + '/' + product.drawing.no"><span>/</span>{{product.drawing.text}}</router-link>
                        <span v-if="product.drawing.no">/</span> {{product.name}}
                    </h1>
                    <div class="searching">
                        <form action="/spareparts/search" method="get">
                            <input type="text" name="q" placeholder="Søg efter reservedel">
                            <button>
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </form>
                    </div>
                </div>
                <v-layout wrap row>
                    <v-flex lg3>
                        <div class="pageProductBox">
                            <img :src="product.image" />
                            <div class="pBoxContent">
                                <h4>{{product.name}}</h4>
                                <h4>
                                    <strong>DKK {{localNumber(product.discountedPrice)}}</strong>
                                    <small v-if="product.price > product.discountedPrice">
                                        <del>{{localNumber(product.price)}}</del> <span>({{getDiscount(product.price, product.discountedPrice)}}%)</span>
                                    </small>
                                </h4>
                                <span class="id">Varenummer: {{product.no}}</span>
                                <!--<span class="varegroup">VVS-nummer: {{product.vvsNumber}}</span>-->
                            </div>
                            <div class="pBoxStatus">
                                <span v-if="product.disponibel > 0"><i class="fa fa-check-circle" aria-hidden="true"></i> På lager</span>
                                <span v-if="product.disponibel < 1"><i class="fa fa-times-circle red--text" aria-hidden="true"></i> Ikke på lager</span>
                                <span v-if="product.disponibel > 0"><i class="fa fa-truck" aria-hidden="true"></i> {{product.afsendes}}<br />{{product.kritiskText}}</span>
                            </div>
                            <div v-if="product.disponibel > 0" class="pBoxAdd">
                                <span><input type="number" v-model="product.quantity" /> Antal</span>
                                <button type="submit" @click="addToBasket(product.no, product.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex lg9>
                        <v-tabs v-model="activeTab">
                            <v-tabs-bar class="productPageTabs">
                                <v-tabs-item :href="'#datablad'" ripple>
                                    Datablade
                                </v-tabs-item>
                            </v-tabs-bar>
                            <v-tabs-items>
                                <v-tabs-content id="datablad">
                                    <div v-for="item in datablade" :key="item.id">
                                        <a :href="item.path">{{item.name}}</a>
                                    </div>
                                </v-tabs-content>
                            </v-tabs-items>
                        </v-tabs>
                    </v-flex>
                </v-layout>
            </div>
            <div class="pageLoad" v-if="!product">
                <div class="spinner"></div>
            </div>
        </div>

        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="errorMessage = null">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            activeTab: 'datablad',
            product: null,
            productGroups: [],
            datablade: [],
            error: {
                show: false,
                message: ''
            }
        }),
        props: ["categories"],
        components: {},
        computed: {
            getCategory() {
                return this.categories.find(x => x.url == this.$route.params.category);
            },
            getProductGroup() {
                return this.productGroups.find(x => x.url == this.$route.params.productGroup);
            }
        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            getDiscount(val1, val2) {
                return this.localNumber((val1 - val2) / val1 * 100);
            },
            getProductGroups() {
                this.$http("/api/spareparts/productgroups", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            getProduct() {
                this.$http("/api/sparepart/product", {
                    params: {
                        productNumber: this.$route.params.product,
                        drawingNumber: this.$route.params.sparepart
                    }
                }).then(x => {
                    this.product = x.data;
                });
            },
            getDatablade() {
                this.$http("/api/product/documents", {
                    params: {
                        productNumber: this.$route.params.product
                    }
                }).then(x => {
                    this.datablade = x.data;
                });
            },
            addToBasket(no, q, e) {
                this.$http("/api/checkout/add", {
                    params: {
                        productNumber: no,
                        quantity: q
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        this.$store.dispatch('getMiniBasket');

                        $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        setTimeout(() => {
                            $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        }, 1500);
                    }
                });
            }
        },
        created() {
            this.getProductGroups();
            this.getProduct();
            this.getDatablade();
        },
        mounted() {

        }
    }
</script>
