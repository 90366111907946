<template>
    <div>
        <v-tabs>
            <v-tabs-bar class="underKategorier">
                <v-tabs-item v-for="item in productGroups" key="item.id" :to="{ name: 'productGroup', params: { category: $route.params.category, productGroup: item.url }}">
                    {{item.name}}
                </v-tabs-item>

                <v-tabs-item :to="{ name: 'exhausts', params: { category: $route.params.category } }" class="exhaustTab">
                    Aftræk
                </v-tabs-item>
            </v-tabs-bar>
        </v-tabs>
        <div class="topline">
            <h1>
                <router-link to="/products">Produkter</router-link><span>/</span>
                <router-link v-if="getCategory" :to="'/products/' + getCategory.url">{{getCategory.name}}</router-link>
                <span>/</span> Aftræk
            </h1>

            <div class="searching">
                <form :action="'/products/' + $route.params.category" method="get">
                    <input type="text" name="q" placeholder="Søg efter produkt">
                    <button>
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>
        <v-layout row wrap>
            <v-flex sm6 md4 lg4 xl3 v-for="item in exhausts" key="item.id">
                <div class="produktBox">
                    <router-link :to="{ name: 'exhaust', params: { id: item.no, category: $route.params.category  } }">
                        <img :src="item.path" />
                    </router-link>
                    <div class="pBoxContent">
                        <span class="id">{{item.no}}</span>
                        <h4>{{item.text}}</h4>
                        <router-link class="goToProduct quickBasket" :to="{ name: 'exhaust', params: { id: item.no, category: $route.params.category  } }">
                            <i class="fa fa-info" aria-hidden="true"></i> Se aftræksløsning
                        </router-link>
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="errorMessage = null">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            productGroups: [],
            exhausts: [],
            error: {
                show: false,
                message: ''
            }
        }),
        props: ["categories"],
        watch: {
            '$route'(to, from) {
                if (from.name == "category" || from.name == "productGroup") {
                    this.getProductGroups();
                    this.getExhausts();
                }
            }
        },
        components: {},
        computed: {
            getCategory() {
                return this.categories.find(x => x.url == this.$route.params.category);
            },
            getProductGroup() {
                return this.productGroups.find(x => x.url == this.$route.params.productGroup);
            }
        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            getProductGroups() {
                this.$http("/api/productgroups", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            getDiscount(val1, val2) {
                return this.localNumber((val1 - val2) / val1 * 100);
            },
            getExhausts() {
                this.$http("/api/category/exhausts", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.exhausts = x.data;
                });
            }

        },
        created() {
            this.getProductGroups();
            this.getExhausts();
        },
        mounted() {

        }
    }
</script>
