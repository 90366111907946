<template>
    <div>
        <v-tabs>
            <v-tabs-bar class="underKategorier">
                <v-tabs-item v-for="item in productGroups" key="item.id" :to="{ name: 'spareparts-productGroup', params: { category: $route.params.category, productGroup: item.url }}">
                    {{item.name}}
                </v-tabs-item>
            </v-tabs-bar>
        </v-tabs>
        <div class="topline">
            <h1>
                <router-link to="/spareparts">Reservedele</router-link><span>/</span>
                <router-link v-if="getCategory" :to="'/spareparts/' + getCategory.url">{{getCategory.name}}</router-link>
                <template v-if="getProductGroup"><span>/</span>{{getProductGroup.name}}</template>
            </h1>
            <div class="searching">
                <form action="/spareparts/search" method="get">
                    <input type="text" name="q" placeholder="Søg efter reservedel">
                    <button>
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>
        <v-layout row wrap>
            <v-flex sm6 md4 lg4 xl3 v-for="item in products" key="item.id"> 
                <div class="produktBox">
                    <router-link :to="{ name: 'spareparts-product', params: { category: $route.params.category, productGroup: item.productGroup, sparepart: item.no  } }">
                        <img :src="item.image" />
                    </router-link>
                    <div class="pBoxContent">
                        <!--<span class="varegroup">{{item.varegruppe}}</span>
                        <span class="id">{{item.no}}</span>-->
                        <h4>{{item.name}}</h4>
                        <router-link class="goToProduct" :to="{ name: 'spareparts-product', params: { category: $route.params.category, productGroup: item.productGroup, sparepart: item.no  } }">
                            <i class="fa fa-info" aria-hidden="true"></i> Se reservedele
                        </router-link>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        data: () => ({
            productGroups: [],
            products: []
        }),
        props: ["categories"],
        watch: {
            '$route'(to, from) {
                if (from.name == "spareparts-category" || from.name == "spareparts-productGroup") {
                    this.getProductGroups();
                    this.getProducts();
                }
            }
        },
        components: {},
        computed: {
            getCategory() {
                return this.categories.find(x => x.url == this.$route.params.category);
            },
            getProductGroup() {
                return this.productGroups.find(x => x.url == this.$route.params.productGroup);
            }},
        methods: {
            getProductGroups() {
                this.$http("/api/spareparts/productgroups", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            getProducts() {
                this.$http("/api/spareparts", {
                    params: {
                        category: this.$route.params.category,
                        productGroup: this.$route.params.productGroup
                    }
                }).then(x => {
                    this.products = x.data;
                });
            }
        },
        created() {
            this.getProductGroups();
            this.getProducts();
        },
        mounted() {

        }
    }
</script>
