import Login from './components/Login/Login.vue';
import ForgotPassword from './components/Login/ForgotPassword.vue';
import ResetPassword from './components/Login/ResetPassword.vue';

import Layout from './components/Layout.vue';

import Home from './components/Home/_Home.vue';

import Admin from './components/Admin/_Admin.vue';

import Products from './components/Products/_Products.vue';
import CategoryNav from './components/Products/_CategoryNav.vue';
import Category from './components/Products/Category.vue';
import Product from './components/Products/Product.vue';
import ProductSearch from './components/Products/Search.vue';
import Exhausts from './components/Products/Exhausts.vue';
import Exhaust from './components/Products/Exhaust.vue';

import AccessoryProduct from './components/Accessories/Product.vue';

import SpareParts from './components/SpareParts/_SpareParts.vue';
import SparePartsCategory from './components/SpareParts/Category.vue';
import SparePartList from './components/SpareParts/SparePartList.vue';
import SparePartProduct from './components/SpareParts/Product.vue';
import SparePartSearch from './components/SpareParts/Search.vue';

import Profile from './components/Profile/_Profile.vue';
import BasicInfo from './components/Profile/BasicInfo.vue';
import Installations from './components/Profile/Installations.vue';
import Installation from './components/Profile/Installation.vue';
import Orders from './components/Profile/Orders.vue';
import Order from './components/Profile/Order.vue';
import Invoices from './components/Profile/Invoices.vue';
import Invoice from './components/Profile/Invoice.vue';
import Points from './components/Profile/Points.vue';
import Register from './components/Profile/Register.vue';
import Opstartsservice from './components/Profile/Opstartsservice.vue';


import Checkout from './components/Checkout/_Checkout.vue';


const routes = [
    {
        path: '/', component: Layout,
        children: [
            { path: '', component: Home, name: 'home' },
            { path: '/admin', component: Admin, name: 'admin' },
            {
                path: 'products', component: Products
            },
            {
                path: 'products/search', component: CategoryNav,
                children: [
                    { path: '', component: ProductSearch, name: 'product-search', meta: { routeName: 'category' } }
                ]
            },
            {
                path: 'products/:category', component: CategoryNav,
                children: [
                    { path: '', component: Category, name: 'category', meta: { routeName: 'category' } },
                    { path: 'exhausts', component: Exhausts, name: 'exhausts', meta: { routeName: 'category' } },
                    { path: 'exhausts/:id', component: Exhaust, name: 'exhaust', meta: { routeName: 'category' } },
                    { path: ':productGroup', component: Category, name: 'productGroup', meta: { routeName: 'category' } },
                    { path: ':productGroup/:product', component: Product, name: 'product', meta: { routeName: 'category' } }
                ]
            },
            {
                path: 'accessories/:product', component: CategoryNav,
                children: [
                    { path: '', component: AccessoryProduct, name: 'accessory', meta: { routeName: 'category' } }
                ]
            },
            { path: 'spareparts', component: SpareParts, name: 'spareparts' },
            {
                path: 'spareparts/search', component: CategoryNav,
                children: [
                    { path: '', component: SparePartSearch, name: 'spareparts-search', meta: { routeName: 'spareparts-category' } },
                ]
            },
            {
                path: 'sparepart/:product', component: CategoryNav,
                children: [
                    { path: '', component: SparePartProduct, name: 'sparepart-product', meta: { routeName: 'spareparts-category' } }
                ]
            },
            {
                path: 'spareparts/:category', component: CategoryNav,
                children: [
                    { path: '', component: SparePartsCategory, name: 'spareparts-category', meta: { routeName: 'spareparts-category' } },
                    { path: ':productGroup', component: SparePartsCategory, name: 'spareparts-productGroup', meta: { routeName: 'spareparts-category' } },
                    { path: ':productGroup/:sparepart', component: SparePartList, name: 'spareparts-product', meta: { routeName: 'spareparts-category' }  },
                    { path: ':productGroup/:sparepart/:product', component: SparePartProduct, name: 'sparepart', meta: { routeName: 'spareparts-category' }  }
                ]
            },
            { path: 'checkout', component: Checkout, name: 'checkout' },
            {
                path: 'profile', component: Profile,
                children: [
                    { path: '', component: BasicInfo, name: 'profile' },
                    { path: 'installations', component: Installations, name: 'installations' },
                    { path: 'installations/:id', component: Installation, name: 'installation' },
                    { path: 'orders', component: Orders, name: 'orders' },
                    { path: 'orders/:id', component: Order, name: 'order' },
                    { path: 'invoices', component: Invoices, name: 'invoices' },
                    { path: 'invoices/:id', component: Invoice, name: 'invoice' },
                    { path: 'points', component: Points, name: 'points' },
                    { path: 'register', component: Register, name: 'register' },
                    { path: 'opstartsservice', component: Opstartsservice, name: 'opstartsservice' }
                ]
            }
        ]
    },
    { path: '/login', component: Login, name: 'login' },
    { path: '/register', component: Login, name: 'login-register' },
    { path: '/forgot-password', component: ForgotPassword, name: 'forgot-password' },
    { path: '/reset-password', component: ResetPassword, name: 'reset-password' }
];

export default routes;