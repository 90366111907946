<template>
    <div>
        <div class="topline">
            <h1>
                Min side <span>/</span>
                <span>Fakturaer</span>
                <span>/</span> {{$route.params.id}}
            </h1>
        </div>
        <div class="pageLoad" v-if="loading">
            <div class="spinner"></div>
        </div>

        <v-layout row wrap v-if="invoice">
            <v-flex xs12>
                <div class="produktEkstra mb-3">
                    <span class="d-inline-block mr-5"><strong>Faktura:</strong> {{invoice.invoice.no}}</span>
                    <span class="d-inline-block mr-5"><strong>Ordrenummer:</strong> {{invoice.invoice.orderNo}}</span>
                    <span class="d-inline-block mr-5"><strong>Dato:</strong> {{$moment(invoice.invoice.orderDate).format('DD-MM-YYYY')}}</span>
                    <span v-if="invoice.invoice.externalDocumentNo" class="d-inline-block mr-5"><strong>Reference:</strong> {{invoice.invoice.externalDocumentNo}}</span>
                </div>
            </v-flex>
            <v-flex xs12>
                <a v-for="item in trackTrace" :href="item.trackTrace" target="_blank" class="trackTraceBtn">
                    Track and trace ({{$moment(item.shipment_Date).format('DD-MM-YYYY')}})
                </a>
            </v-flex>
            <v-flex xs12 sm4 v-for="item in invoice.invoiceLines" :key="item.no">
                <div class="produktEkstra fakturaList">
                    <ul>
                        <li>
                            <span class="fakturanummer"><strong>Varenr.</strong> {{item.no}}</span>
                        </li>
                        <li>
                            <span class="dato"><strong>Varebeskrivelse:</strong> {{item.description}}</span>
                        </li>
                        <li v-if="item.discountText">
                            <span class="dato"><strong>Rabat:</strong> {{item.discountText}}</span>
                        </li>
                        <li>
                            <span class="ordrernummer"><strong>Antal:</strong> {{item.quantity}}</span>
                        </li>
                        <li>
                            <span class="ordrernummer"><strong>Enhedspris:</strong> {{localNumber(item.unitPrice)}}</span>
                        </li>
                        <li>
                            <span class="fakturanummer"><strong>Totalpris:</strong> {{localNumber(item.amount)}}</span>
                        </li>
                    </ul>
                </div>
            </v-flex>
        </v-layout>


    </div>
</template>

<script>
    export default {
        data: () => ({
            invoice: null,
            trackTrace: [],
            loading: true
        }),
        components: {},
        computed: {

        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            }
        },
        created() {
            this.$http.get("/api/profile/invoices/" + this.$route.params.id).then(x => {
                this.invoice = x.data;

                this.$http.get("/api/profile/invoices/trackandtrace/" + x.data.invoice.orderNo).then(t => {
                    this.trackTrace = t.data;
                    this.loading = false;
                });
            });
        },
        mounted() {

        }
    }
</script>
