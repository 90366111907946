<template>
    <div>
        <div class="topline">
            <h1><span>Min side</span></h1>
        </div>

        <div class="pageLoad" v-if="pageLoading">
            <div class="spinner"></div>
        </div>

        <div v-if="profile">
            <div class="newPassword">
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Navn</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="navn"
                                      disabled
                                      v-model="profile.name"
                                      label="Navn"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Firmanavn</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="Firmanavn"
                                      disabled
                                      v-model="profile.firma"
                                      label="Firmanavn"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Adresse</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="Adresse"
                                      disabled
                                      v-model="profile.adresse"
                                      label="Adresse"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Postnummer</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="Postnummer"
                                      disabled
                                      v-model="profile.postnr"
                                      label="Postnummer"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>By</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="By"
                                      disabled
                                      v-model="profile.by"
                                      label="By"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Firmatelefon</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="Firmatelefon"
                                      disabled
                                      v-model="profile.firmaTelefon"
                                      label="Firmatelefon"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>E-mail</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="mail"
                                      disabled
                                      v-model="profile.email"
                                      label="E-mail"></v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Kundenummer</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="Kundenummer"
                                      disabled
                                      v-model="profile.kundenummer"
                                      label="Kundenummer"></v-text-field>
                    </v-flex>
                </v-layout>
                <br />
                <p>Tilpasninger af ovenstående informationer kan kun foretages af en administrator fra HS Tarm A/S, kontakt os venligst for at tilpasse oplysningerne.</p>

            </div>

            <div class="newPassword pt-0 pb-4" v-if="!showChangePassword">
                <v-btn @click="showChangePassword = true" class="mb-2"><i class="fa fa-lock" aria-hidden="true"></i> Ændr adgangskode</v-btn>
            </div>


            <section class="newPassword" v-if="showChangePassword">
                <v-layout row wrap>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Gammel adgangskode</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="gammelkode"
                                      label="Gammel adgangskode"
                                      hint="Minimum 6 karakterer"
                                      min="6"
                                      type="password"
                                      v-model="oldPassword"
                                      counter="6"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex class="hidden-sm-and-down" md3>
                        <v-subheader>Ny adgangskode</v-subheader>
                    </v-flex>
                    <v-flex sm12 md9>
                        <v-text-field name="nykode"
                                      label="Ny adgangskode"
                                      hint="Minimum 6 karakterer"
                                      type="password"
                                      min="6"
                                      v-model="newPassword"
                                      counter="6"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-btn :loading="loading" :disabled="loading" @click="changePassword"><i class="fa fa-floppy-o" aria-hidden="true"></i> Gem ny adgangskode</v-btn>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            profile: null,
            oldPassword: null,
            newPassword: null,
            pageLoading: true,
            loading: false,
            showChangePassword: false
        }),
        components: {},
        computed: {

        },
        methods: {
            changePassword() {
                this.$http.post("/api/profile/changepassword", { oldPassword: this.oldPassword, newPassword: this.newPassword }).then(x => {
                    this.oldPassword = null;
                    this.newPassword = null;
                    this.loading = false;
                });
            }
        },
        created() {
            this.$http.get("/api/profile").then(x => {
                this.profile = x.data;
                this.pageLoading = false;
            });
        },
        mounted() {

        }
    }
</script>
