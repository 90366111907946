<template>
    <div class="innerPadding">
        <v-container>
            <v-tabs class="profile">
                <v-tabs-bar class="underKategorier">
                    <v-tabs-item to="/profile" exact>
                        Min side
                    </v-tabs-item>
                    <v-tabs-item to="/profile/opstartsservice">
                        Opstartsservice
                    </v-tabs-item>
                    <v-tabs-item to="/profile/register">
                        Registrer garantikort
                    </v-tabs-item>
                    <v-tabs-item to="/profile/installations">
                        Installationer
                    </v-tabs-item>
                    <v-tabs-item to="/profile/orders">
                        Ordrer
                    </v-tabs-item>
                    <v-tabs-item to="/profile/invoices">
                        Fakturaer
                    </v-tabs-item>
                    <a class="tabs__item" href="https://www.econet24.com/" target="_blank">
                        Fjernstyring <i class="fa fa-external-link-square" aria-hidden="true"></i>
                    </a>
                </v-tabs-bar>
            </v-tabs>

            <router-view></router-view>
        </v-container>
    </div>
</template>

<script>
    export default {
        data: () => ({

        }),
        components: {},
        computed: {

        },
        methods: {

        },
        created() {

        },
        mounted() {

        }
    }
</script>
