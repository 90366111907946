<template>
    <div>
        <div v-if="installation">
            <div class="topline">
                <h1>Min side <span>/</span> Installationer</h1>
            </div>
        </div>
        <div class="pageLoad" v-if="loading">
            <div class="spinner"></div>
        </div>
        <div v-if="installation">
            <table class="datatable table">
                <tbody>
                    <tr>
                        <th class="text-xs-left">Installationsnummer</th>
                        <th class="text-xs-left">{{installation.no}}</th>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Beskrivelse</th>
                        <td class="text-xs-left">{{installation.description}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Serienummer</th>
                        <td class="text-xs-left">{{installation.serialNo}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Installationsdato</th>
                        <td class="text-xs-left">{{$moment(installation.installationDate).format('DD. MMMM YYYY')}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Varenummer</th>
                        <td class="text-xs-left">{{installation.itemNo}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Navn</th>
                        <td class="text-xs-left">{{installation.shiptoName}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Adresse</th>
                        <td class="text-xs-left">{{installation.shiptoAddress}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Postnummer og by</th>
                        <td class="text-xs-left">{{installation.shiptoPostCode}} {{installation.shiptoCity}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">Telefon</th>
                        <td class="text-xs-left">{{installation.shiptoPhoneNo}}</td>
                    </tr>
                    <tr>
                        <th class="text-xs-left">E-mail</th>
                        <td class="text-xs-left">{{installation.shiptoEmail}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            installation: null,
            loading: true

        }),
        components: {},
        computed: {

        },
        methods: {

        },
        created() {
            this.$http.get("/api/profile/installation", {
                params: {
                    id: this.$route.params.id
                }
            }).then(x => {
                this.installation = x.data;
                this.loading = false;

            });
        },
        mounted() {

        }
    }
</script>
