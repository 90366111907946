<template>
    <div class="innerPadding">
        <v-container>
            <h4>Status:</h4>
            <p>
                <v-btn color="orange darken-2" class="white--text" href="/hangfire/jobs/processing" target="_blank">Hangfire job status</v-btn>
            </p>
            <p>
                <v-btn @click="runTask('/api/importproducts')" :loading="loading" :disabled="loading" color="primary" type="button">Importér produkter</v-btn>
                <v-btn @click="runTask('/api/importcategories')" :loading="loading" :disabled="loading" color="primary" type="button">Importér kategorier</v-btn>
                <v-btn @click="runTask('/api/importdiscounts')" :loading="loading" :disabled="loading" color="primary" type="button">Importér rabatter</v-btn>
                <v-btn @click="runTask('/api/importproductfilters')" :loading="loading" :disabled="loading" color="primary" type="button">Importér filtrer</v-btn>
            </p>

            <p>
                <v-btn @click="runTask('/api/importexhaust')" :loading="loading" :disabled="loading" color="primary" type="button">Importér aftræk</v-btn>
                <v-btn @click="runTask('/api/importaccessories')" :loading="loading" :disabled="loading" color="primary" type="button">Importér tilbehør</v-btn>
                <v-btn @click="runTask('/api/importassemblies')" :loading="loading" :disabled="loading" color="primary" type="button">Importér produkt tilbehør</v-btn>
                <v-btn @click="runTask('/api/importspareparts')" :loading="loading" :disabled="loading" color="primary" type="button">Importér reservedele</v-btn>
            </p>

            <p>
                <v-btn @click="runTask('/api/importcustomers')" :loading="loading" :disabled="loading" color="primary" type="button">Importér kunder</v-btn>
                <v-btn @click="runTask('/api/importinstallers')" :loading="loading" :disabled="loading" color="primary" type="button">Importér installatører</v-btn>
            </p>

            <p>
                <v-btn @click="runTask('/api/importvarebilleder')" :loading="loading" :disabled="loading" color="primary" type="button">Importér varebilleder</v-btn>
                <v-btn @click="runTask('/api/importvaredokumenter')" :loading="loading" :disabled="loading" color="primary" type="button">Importér varedokumenter</v-btn>
            </p>
        </v-container>
    </div>
</template>

<script>
    export default {
        data: () => ({
            loading: false
        }),
        components: { },
        computed: {

        },
        methods: {
            runTask(url) {
                this.loading = true;
                this.$http.get(url).then(x => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                });
            }
        },
        created() {

        },
        mounted() {

        }
    }
</script>