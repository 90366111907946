<template>
    <div class="heightFix">
        <v-navigation-drawer touchless absolute app class="sidebar" v-model="showDrawer">
            <router-link v-for="item in categories" key="item.id" :to="{ name: $route.meta.routeName, params: { category: item.url }}">
                <img :src="'/img/categories/' + item.code + '.png'" />
                <span>{{item.name}}</span>
            </router-link>
        </v-navigation-drawer>
        <v-content>
            <div class="totalPadding">
                <div class="toggleDrawer hidden-lg-and-up">
                    <button @click="showDrawer = !showDrawer">Skift kategori <i class="fa fa-cog" aria-hidden="true"></i></button>
                </div>
                <router-view :categories="categories"></router-view>
            </div>
        </v-content>
    </div>
</template>

<script>
    export default {
        data: () => ({
            categories: [],
            showDrawer: true
        }),
        components: {},
        computed: {

        },
        methods: {
            width() {
                return $(window).width();
            }
        },
        created() {
            if (this.$route.name.indexOf('spareparts') > -1) {
                this.$http("/api/spareparts/categories").then(x => {
                    this.categories = x.data;
                });
            } else {
                this.$http("/api/categories").then(x => {
                    this.categories = x.data;
                });
            }
        },
        mounted() {
            if (this.width() < 990) {
                setTimeout(() => {
                    this.showDrawer = false;
                }, 300);
            }
        }
    }
</script>
