<template>
    <div class="filler">
        <v-container>
            <v-layout row wrap justify-center>
                <v-flex xs12 sm8 md6>
                    <div class="px-4">
                        <img class="loginLogo" src="/img/logo.png" />
                        <img class="loginBaxiLogo" src="/img/baxilogo.png" />
                    </div>

                    <div class="floatCard">
                        <h2>Opret log ind</h2>

                        <form v-on:submit.prevent="submitForm" novalidate class="logOn">
                            <v-layout row wrap>
                                <v-flex xs12 sm12>
                                    <v-text-field name="email" v-model="form.email"
                                                  placeholder="E-mail"
                                                  :error-messages="errors.collect('email')"
                                                  v-validate="'required|email'"
                                                  data-vv-as="E-mail"
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12>
                                    <v-text-field name="name" v-model="form.name"
                                                  placeholder="Navn"
                                                  :error-messages="errors.collect('name')"
                                                  v-validate="'required'"
                                                  data-vv-as="Navn"
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12>
                                    <v-text-field name="stilling" v-model="form.stilling"
                                                  placeholder="Stilling"></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6>
                                    <v-text-field name="mobilePhone" v-model="form.mobilePhone"
                                                  placeholder="Mobiltelefon"></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6>
                                    <v-text-field name="phone" v-model="form.phone"
                                                  placeholder="Telefon (direkte)"></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm4 py-4>
                                    <h3 class="ma-0">Indehaver</h3>
                                </v-flex>
                                <v-flex xs12 sm8>
                                    <v-radio-group row name="indehaver" v-model="form.indehaver">
                                        <v-radio label="Ja" :value="true"></v-radio>
                                        <v-radio label="Nej" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-flex>

                                <v-flex xs12 sm12>
                                    <v-text-field name="company" v-model="form.company"
                                                  placeholder="Firmanavn"
                                                  :error-messages="errors.collect('company')"
                                                  v-validate="'required'"
                                                  data-vv-as="Firmanavn"
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12>
                                    <v-text-field name="address" v-model="form.address"
                                                  placeholder="Adresse"
                                                  :error-messages="errors.collect('address')"
                                                  v-validate="'required'"
                                                  data-vv-as="Adresse"
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6>
                                    <v-text-field name="zipCode" v-model="form.zipCode"
                                                  placeholder="Postnr."
                                                  :error-messages="errors.collect('zipCode')"
                                                  v-validate="'required'"
                                                  data-vv-as="Postnr."
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6>
                                    <v-text-field name="city" v-model="form.city"
                                                  placeholder="By"
                                                  :error-messages="errors.collect('city')"
                                                  v-validate="'required'"
                                                  data-vv-as="By"
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12>
                                    <v-text-field name="companyPhone" v-model="form.companyPhone"
                                                  placeholder="Firmatelefon"
                                                  :error-messages="errors.collect('companyPhone')"
                                                  v-validate="'required'"
                                                  data-vv-as="Firmatelefon"
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12>
                                    <v-text-field name="vatNumber" v-model="form.vatNumber"
                                                  placeholder="CVR-nr."
                                                  :error-messages="errors.collect('vatNumber')"
                                                  v-validate="'required'"
                                                  data-vv-as="CVR-nr."
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6>
                                    <v-text-field name="password" v-model="form.password"
                                                  ref="password"
                                                  placeholder="Adgangskode"
                                                  type="password"
                                                  :error-messages="errors.collect('password')"
                                                  v-validate="'required'"
                                                  data-vv-as="Adgangskode"
                                                  required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field name="confirmPassword" v-model="form.confirmPassword"
                                                  placeholder="Bekræft adgangskode"
                                                  type="password"
                                                  :error-messages="errors.collect('confirmPassword')"
                                                  v-validate="'required|confirmed:password'"
                                                  data-vv-as="Bekræft adgangskode"
                                                  required></v-text-field>
                                </v-flex>

                                <v-flex xs12 mt-3>
                                    <v-checkbox label="Tilmeld nyhedsbrev" v-model="form.newsletter" light></v-checkbox>
                                </v-flex>

                                <!--<v-flex xs12 mt-3>
                                    <v-checkbox label="Opret samtidigt hos AddEnergy" v-model="form.addEnergy" light persistent-hint
                                                :hint="'<a href=\'https://hstarm.dk/addenergy\' target=\'_blank\' class=\'orange--text text--darken-2\'>Læs information omkring AddEnergy her</a>'"></v-checkbox>
                                </v-flex>-->

                                <!-- https://hstarm.dk/om-hs-tarm/privatlivspolitik -->

                                <v-flex xs12 mt-3>
                                    <v-checkbox label="Acceptér HS Tarm A/S' privatlivspolitik" v-model="form.accept" light :error="errors.has('acceptTerms')"
                                                :error-messages="errors.has('acceptTerms') ? 'Du skal acceptere privatlivspolitiken' : []"
                                                :hint="'<a href=\'https://hstarm.dk/om-hs-tarm/privatlivspolitik\' target=\'_blank\' class=\'orange--text text--darken-2\'>Læs privatlivspolitiken her</a>'" persistent-hint>
                                    </v-checkbox>
                                    <input type="checkbox" v-validate="'required'" name="acceptTerms" v-model="form.accept" style="display: none;" />
                                </v-flex>

                                <v-flex xs12 class="text-xs-center mt-3">
                                    <v-btn class="loginBtn trical2" type="submit" :loading="loading" :disabled="loading">Opret log ind <i class="fa fa-sign-in" aria-hidden="true"></i></v-btn>
                                </v-flex>

                                <v-flex xs12 class="text-xs-center pa-4">
                                    <router-link class="orange--text text--darken-2" to="/login">
                                        Log på
                                    </router-link>
                                </v-flex>
                            </v-layout>
                        </form>

                        <v-alert color="success" class="mt-3" icon="check_circle" v-model="success">
                            Tak for din forespørgsel. Vi vender tilbage hurtigst muligt.
                        </v-alert>

                        <v-alert color="error" class="mt-3" icon="warning" v-model="error">
                            Der opstod en fejl. Tjek dine olysninger eller kontakt os direkte.
                        </v-alert>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                xsrfToken: null,
                form: { indehaver: null, newsletter: false, accept: false },
                loading: false,
                success: false,
                error: false
            };
        },
        components: {},
        computed: {

        },
        methods: {
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result === true) {
                        axios.post('/api/register', this.form, { headers: { 'X-XSRF-TOKEN': this.xsrfToken } }).then(response => {
                            if (response.data === true) {
                                this.errors.clear();
                                this.form = { indehaver: null, newsletter: false };

                                this.success = true;
                                this.error = false;
                            } else {
                                this.error = true;
                                this.success = false;
                            }
                        }).catch(err => {
                            this.error = true;
                            this.success = false;
                        });

                        return;
                    }
                });
            }
        },
        created() {

        },
        mounted() {
            this.xsrfToken = $('#xsrfToken').val();
        }
    }
</script>
