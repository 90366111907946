<template>
    <div>
        <v-tabs>
            <v-tabs-bar class="underKategorier">
                <v-tabs-item v-for="item in productGroups" key="item.id" :to="{ name: 'productGroup', params: { category: $route.params.category, productGroup: item.url }}">
                    {{item.name}}
                </v-tabs-item>

                <v-tabs-item :to="{ name: 'exhausts', params: { category: $route.params.category } }" class="exhaustTab">
                    Aftræk
                </v-tabs-item>
            </v-tabs-bar>
        </v-tabs>
        <div class="topline">
            <h1>
                <router-link to="/products">Produkter</router-link><span>/</span>
                <router-link v-if="getCategory" :to="'/products/' + getCategory.url">{{getCategory.name}}</router-link>
                <span>/</span>
                <router-link v-if="getCategory" :to="'/products/' + $route.params.category + '/exhausts'">Aftræk</router-link>
                <span>/</span> {{exhaust.text}}
            </h1>

            <div class="searching">
                <form :action="'/products/' + $route.params.category" method="get">
                    <input type="text" name="q" placeholder="Søg efter produkt">
                    <button>
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>

        <div v-if="exhaust">
            <img :src="exhaust.path" />

            <v-layout row wrap class="package">
                <v-flex xs12>
                    <div class="produktEkstra listReserve">
                        <v-btn dark class="orange darken-1 packageButton" @click="packageActive = true;"
                               v-if="!packageActive">Dan pakke<span class="fa fa-archive ml-3"></span></v-btn>
                        <v-btn dark class="green packageButton" @click="buyPackage" :loading="packageLoading"
                               v-if="packageActive">Læg pakke i kurv<span class="fa fa-shopping-cart ml-3"></span></v-btn>
                        <v-btn dark class="red packageButton" @click="package = []; packageActive = false;"
                               :loading="packageLoading" v-if="packageActive">Annuller pakke<span class="fa fa-trash ml-3"></span></v-btn>

                        <span style="display: inline-block; padding: 12px 12px 0px;" v-if="!packageActive">
                            Tryk på "DAN PAKKE" for at samle alle dele til aftrækket, før de samlet lægges i kurven
                        </span>

                        <span style="display: inline-block; padding: 12px 12px 0px;" v-if="packageActive">
                            Vælg varer fra nedenstående liste til pakken. Når alle nødvendige dele og antal er lagt i pakken, trykkes på "LÆG PAKKE I KURV"
                        </span>
                    </div>
                </v-flex>

                <v-flex xs12 sm12 md6 lg4 xl4 class="assemblyItems" v-for="item in exhaustItems" :key="item.no">
                    <ul class="produktEkstra listReserve">
                        <li class="position">
                            <span class="circle">{{item.position}}</span>
                        </li>
                        <li>
                            <img :src="item.image" />
                        </li>
                        <li>
                            <span class="varenummer">{{item.no}}</span><br />
                            <span class="produktnavn">{{item.name}}</span>

                        </li>
                        <li class="addEkstra">
                            <span class="produktpris">
                                <strong>DKK {{localNumber(item.discountedPrice)}}</strong>
                                <small v-if="item.price > item.discountedPrice">
                                    <del>{{localNumber(item.price)}}</del> ({{getDiscount(item.price, item.discountedPrice)}}%)
                                </small>
                            </span>
                            <span v-if="packageActive && package.find(x => x.no == item.no)">Antal <input type="number" v-model="package.find(x => x.no == item.no).q" /></span>
                            <span v-else>Antal <input type="number" v-model="item.quantity" /></span>
                        </li>
                        <li class="addEkstra">
                            <button v-if="!packageActive" type="submit" @click="addToBasket(item.no, item.quantity, $event)" class="quickBasket">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                            <button v-if="packageActive && !package.find(x => x.no == item.no)" type="submit" @click="addToPackage(item.no, item.quantity)" class="quickBasket">Vælg til pakke <i class="fa fa-archive" aria-hidden="true"></i></button>
                            <button v-if="packageActive && package.find(x => x.no == item.no)" type="submit" @click="removeFromPackage(item.no)" class="quickBasket orange">Fjern fra pakke <i class="fa fa-archive" aria-hidden="true"></i></button>
                        </li>
                    </ul>
                </v-flex>
            </v-layout>
        </div>


        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="errorMessage = null">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            productGroups: [],
            exhausts: [],
            error: {
                show: false,
                message: ''
            },
            package: [],
            packageActive: false,
            packageLoading: false,
            exhaust: null,
            exhaustItems: []
        }),
        props: ["categories"],
        watch: {
            '$route'(to, from) {
                if (from.name == "category" || from.name == "productGroup") {
                    this.getProductGroups();
                    this.getExhausts();
                }
            }
        },
        components: {},
        computed: {
            getCategory() {
                return this.categories.find(x => x.url == this.$route.params.category);
            },
            getProductGroup() {
                return this.productGroups.find(x => x.url == this.$route.params.productGroup);
            }
        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            getProductGroups() {
                this.$http("/api/productgroups", {
                    params: {
                        category: this.$route.params.category
                    }
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            getDiscount(val1, val2) {
                return this.localNumber((val1 - val2) / val1 * 100);
            },
            getExhaust() {

                this.$http.get("/api/category/exhaust", {
                    params: {
                        no: this.$route.params.id
                    }
                }).then(x => {
                    this.exhaust = x.data;
                });

                this.$http.get("/api/product/exhaustitems", {
                    params: {
                        category: this.$route.params.category,
                        drawing: this.$route.params.id
                    }
                }).then(x => {
                    this.exhaustItems = x.data;
                });
            },
            addToBasket(no, q, e) {
                this.$http("/api/checkout/add", {
                    params: {
                        productNumber: no,
                        quantity: q
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        this.$store.dispatch('getMiniBasket');

                        $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        setTimeout(() => {
                            $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        }, 1500);
                    }
                });
            },
            addToPackage(no, q, e) {
                var items = this.package;
                var item = items.find(x => x.no == no);

                if (item) {
                    q += item.q;
                }

                this.$http("/api/product/package/add", {
                    params: {
                        productNumber: no,
                        quantity: q
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        if (item) {
                            item.q = q;
                            this.package = items;
                        } else {
                            this.package.push({ no: no, q: q });
                        }
                    }
                });
            },
            removeFromPackage(no) {
                var item = this.package.indexOf(this.package.find(x => x.no == no));
                if (item > -1) {
                    this.package.splice(item, 1);
                }
            },
            buyPackage() {
                var loader = 0;
                for (var i = 0; i < this.package.length; i++) {
                    var item = this.package[i];
                    this.packageLoading = true;

                    this.$http("/api/checkout/add", {
                        params: {
                            productNumber: item.no,
                            quantity: item.q
                        }
                    }).then(x => {
                        loader++;

                        if (x.data.error) {
                            this.error.show = true;
                            this.error.message = x.data.error;
                        } else {
                            this.$store.dispatch('getMiniBasket');
                        }

                        if (loader >= this.package.length) {
                            this.packageLoading = false;
                            this.packageActive = false;
                            this.package = [];
                        }
                    });
                }
            }

        },
        created() {
            this.getProductGroups();
            this.getExhaust();
        },
        mounted() {
        }
    }
</script>
