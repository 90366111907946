<template>
    <div>
        <div class="topline">
            <h1>Min side <span>/</span> <span>Fakturaer</span></h1>

            <div class="searching">
                <form>
                    <input type="text" v-model="query" name="q" placeholder="Søg efter faktura">
                    <button type="button">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>
        <div class="pageLoad" v-if="loading">
            <div class="spinner"></div>
        </div>

        <v-layout row wrap>
            <v-flex xs12 sm4 v-for="item in filteredInvoices" :key="item.no">
                <div class="produktEkstra fakturaList">
                    <ul>
                        <li>
                            <span class="fakturanummer">FAKTURA {{item.no}}</span>
                        </li>
                        <li>
                            <span class="dato">{{item.ordreDato}}</span>
                        </li>
                        <li>
                            <span class="ordrernummer">Ordre {{item.orderNo}}</span>
                        </li>
                        <li>
                            <span class="reference">{{item.eksterntBilagsNummer}}</span>
                        </li>
                        <li class="goRight">
                            <!--<a class="tt" href="https://www.consignorportal.com/" target="_blank">Track & Trace<i class="fa fa-truck" aria-hidden="true"></i></a>-->
                            <router-link class="seIndhold" :to="'/profile/invoices/' + item.no">Se fakturaen <i class="fa fa-info" aria-hidden="true"></i></router-link>
                        </li>
                    </ul>
                </div>
            </v-flex>
        </v-layout>


    </div>
</template>

<script>
    export default {
        data: () => ({
            invoices: [],
            loading: true,
            query: null
        }),
        components: {},
        computed: {
            filteredInvoices() {
                if (this.query == null || this.query == '') {
                    return this.invoices;
                }

                return this.invoices.filter(x =>
                    (x.no && x.no.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.orderNo && x.orderNo.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.eksterntBilagsNummer && x.eksterntBilagsNummer.toLowerCase().includes(this.query.toLowerCase())) ||
                    (x.ordreDato && x.ordreDato.toLowerCase().includes(this.query.toLowerCase()))
                );
            }
        },
        methods: {

        },
        created() {
            this.$http.get("/api/profile/invoices").then(x => {
                this.invoices = x.data;
                this.loading = false;
            });
        },
        mounted() {

        }
    }
</script>
