<template>
    <div>
        <div class="topline">
            <h1>Søg</h1>
            <div class="searching">
                <form action="/products/search" method="get">
                    <input type="text" v-model="search" name="q" placeholder="Søg efter produkt">
                    <button>
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </div>
        <v-layout row wrap>
            <v-flex sm6 md4 lg4 xl3 v-for="item in products" key="item.id">
                <div class="produktBox">
                    <router-link :to="item.url">
                        <img :src="item.image" />
                    </router-link>
                    <div class="pBoxContent">
                        <span class="id">{{item.no}}</span>
                        <h4>{{item.name}}</h4>
                        <!--<div class="mb-2">VVS-nummer: {{item.vvsNumber}}</div>-->
                        <h4><strong>DKK {{localNumber(item.discountedPrice)}}</strong> <small v-if="item.price > item.discountedPrice"><del>{{localNumber(item.price)}}</del> <span>({{getDiscount(item.price, item.discountedPrice)}}%)</span></small></h4>
                        <router-link class="goToProduct" :to="item.url">
                            <i class="fa fa-info" aria-hidden="true"></i> Læs mere
                        </router-link>
                        <button class="quickBasket" @click="addToBasket(item.no, $event)">Læg i kurv <i class="fa fa-shopping-cart" aria-hidden="true"></i></button>
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <v-snackbar :timeout="4000"
                    color="error"
                    multi-line
                    v-model="error.show">
            {{ error.message }}
            <v-btn dark flat @click.native="errorMessage = null">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data: () => ({
            products: [],
            error: {
                show: false,
                message: ''
            },
            search: null
        }),
        props: ["categories"],
        components: {},
        computed: {

        },
        methods: {
            localNumber(n) {
                if (n) {
                    return parseFloat(parseFloat(n).toFixed(2)).toLocaleString("da", { minimumFractionDigits: 2 });
                } else {
                    return 0;
                }
            },
            getDiscount(val1, val2) {
                return this.localNumber((val1 - val2) / val1 * 100);
            },
            getProducts() {
                this.$http("/api/products/search", {
                    params: {
                        query: this.search
                    }
                }).then(x => {
                    this.products = x.data;
                });
            },
            addToBasket(no, e) {
                this.$http("/api/checkout/add", {
                    params: {
                        productNumber: no,
                        quantity: 1
                    }
                }).then(x => {
                    if (x.data.error) {
                        this.error.show = true;
                        this.error.message = x.data.error;
                    } else {
                        this.$store.dispatch('getMiniBasket');

                        $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        setTimeout(() => {
                            $(e.target).toggleClass("green").find("i").toggleClass("fa-shopping-cart").toggleClass("fa-check");
                        }, 1500);
                    }
                });
            }
        },
        created() {
            if (this.$route.query != null) {
                this.search = this.$route.query.q;
            }

            this.getProducts();
        },
        mounted() {

        }
    }
</script>
